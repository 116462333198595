import React from  "react"
import { createContext, useContext, useState } from "react"

const GlobalContext = createContext();

export const GlobalContextProvider = ({children}) =>{
    const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <GlobalContext.Provider value={{ isCollapsed, toggleCollapse }}>
      {children}
    </GlobalContext.Provider>
  );
};
export const useCollapse = () => useContext(GlobalContext);