import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";

// Images
import userProfile from "../../assets/images/profile/user-1.jpg";

const Main = () => {
  const [data, setData] = useState([]);
  const [enqData, setEnqData] = useState([]);
  const userDetails = sessionStorage.getItem("info");
  const useInfo = JSON.parse(userDetails);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("info") == null) {
      navigate("/");
    }
  }, [navigate]);

  const handleDashboardData = async () => {
    try {
      const response = await axios.get(
        API_BASE_URL + "api/admin/dashboard_data",
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("dashboard data response", response.data);
      console.log("Session values:", useInfo);
      setData(response.data.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // Fetch latest Enquiries
  const handleEnquiryData = async () => {
    const finalData = { page: 1, page_limit: 5 };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/enquiries_list",finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("Enquiry data response", response.data);
      // setEnqData(response.data.data);
      setEnqData(response.data.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    handleEnquiryData();
    handleDashboardData();
  }, []);

  const formattedDate = (date) => {
    const myDate = new Date(date);
    return `${String(myDate.getDate()).padStart(2, "0")}-${String(
      myDate.getMonth() + 1
    ).padStart(2, "0")}-${myDate.getFullYear()}`;
  };

  function getInitials(name) {
  if (!name || typeof name !== 'string') {
    return '';
  }

  const parts = name.trim().split(" ");
  const initials = parts.map((part) => part.charAt(0)).join("");
  return initials.toUpperCase();
}


  function getRandomColorClass() {
    const colors = [
      "bg-info",
      "bg-success",
      "bg-secondary",
      "bg-warning",
      "bg-primary",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }

  const pendingInquiries = enqData.filter(
    (inquiry) => inquiry.status === "Pending"
  );

  const servedInquiries = enqData.filter(
    (inquiry) => inquiry.status === "Done"
  );

  // Count of inquiries with status 'Done' & 'Pending'
  const pendingInquiriesCount = pendingInquiries.length;

  const totalSale = enqData.reduce((accumulator, currentItem) => {
    // Check if currentItem.amount and currentItem.revised_amount exist and are valid numbers
    const amount = parseInt(currentItem.amount || 0) || 0;
    const revisedAmount = parseInt(currentItem.revised_amount || 0) || 0;

    // Add current item's amount and revised_amount to the accumulator
    return accumulator + amount + revisedAmount;
  }, 0);

  // current date
  const currentDate = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDateNew = currentDate.toLocaleDateString("en-US", options);


  
  return (
    <>
      <Sidebar />
      <div className="body-wrapper">
        <Header />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center gap-4 mb-4">
                <div className="position-relative">
                  <div className="border border-2 border-primary rounded-circle">
                    <img
                      src={userProfile}
                      className="rounded-circle m-1"
                      alt="user1"
                      width="60"
                    />
                  </div>
                </div>
                <div>
                  <h3 className="fw-semibold">
                    Hi, <span className="text-dark">{useInfo?.user_name}</span>
                  </h3>
                  <span>Cheers, and happy activities - {formattedDateNew}</span>
                </div>
              </div>
              <div className="card">
                <div className="border-top"></div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-3 col-lg-4">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-primary shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-users text-primary"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-primary mt-1 mb-1">
                            Total Employees
                            <hr className="mt-2 mb-2 text-primary" />
                          </p>
                          <h4 className="fw-semibold text-primary mb-0">
                            {data?.employee || 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-4">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-warning shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-users text-warning"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-warning mt-1 mb-1">
                            Total Customers
                            <hr className="mt-2 mb-2 text-warning" />
                          </p>
                          <h4 className="fw-semibold text-warning mb-0">
                            {data?.customers || 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-4">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-success shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-chart-line text-success"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-success mt-1 mb-1">
                            Total Sale
                            <hr className="mt-2 mb-2 text-success" />
                          </p>
                          <h4 className="fw-semibold text-success mb-0">
                            {totalSale || 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-4">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-info shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-file-description text-info"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-info mt-1 mb-1">
                            Total Enquiries
                            <hr className="mt-2 mb-2 text-info" />
                          </p>
                          <h4 className="fw-semibold text-info mb-0">
                            {data?.job_forms || 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-4">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-warning shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-files text-warning"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-warning mt-1 mb-1">
                            Served Enquiries
                            <hr className="mt-2 mb-2 text-warning" />
                          </p>
                          <h4 className="fw-semibold text-warning mb-0">
                            {servedInquiries?.length || 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-4">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-danger shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-file-description text-danger"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-danger mt-1 mb-1">
                            Pending Enquiries
                            <hr className="mt-2 mb-2 text-danger" />
                          </p>
                          <h4 className="fw-semibold text-danger mb-0">
                            {pendingInquiriesCount}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* List of Enquiris */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="fw-semibold mb-3">Recent Enquiries</h4>
                  <hr />
                  <div className="table-responsive">
                    <table
                      id="custEnquiry"
                      className="table border table-striped table-bordered text-nowrap m-t-30 table-hover"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Company Name</th>
                          <th>User Name</th>
                          {/* <th>Payment</th> */}
                          {/* <th>Amount Paid</th> */}
                          <th>Enquiry Type</th>
                          <th>Enquiry Date</th>
                          {/* <th>Site Address</th> */}
                          <th>Assigned</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {enqData.length > 0 ? (
                          enqData.map((item, index) => (
                            <tr key={index} className={getRandomColorClass()}>
                              <td>
                                <Link to={`/admin/job-detail/${item.id}`}>
                                  {index + 1}
                                </Link>
                              </td>
                              <td>
                                {item.company_name.length > 15
                                  ? `${item.company_name.slice(0, 30)}...`
                                  : item.company_name}
                              </td>
                              <td>
                                <Link
                                  to={`/admin/customer-profile/${item.user_id}`}
                                >
                                  {item.customer_name}
                                </Link>
                              </td>

                              {/* <td>
                                {item.payment_id === null ? (
                                  <span className="badge fs-2 rounded-pill text-bg-warning">
                                    <b>Pending</b>
                                  </span>
                                ) : (
                                  <span className="badge fs-2 rounded-pill text-bg-success">
                                    <b>Paid</b>
                                  </span>
                                )}
                              </td> */}
                              {/* <td>{item.amount}</td> */}
                              <td>
                                {item.is_revised === "N" ? (
                                  <span className="badge fs-2 text-bg-primary badge-width">
                                    <b>New</b>
                                  </span>
                                ) : (
                                  <span className="badge fs-2 text-bg-secondary badge-width">
                                    <b>Revised</b>
                                  </span>
                                )}
                              </td>
                              <td>{formattedDate(item.created_at)}</td>
                              {/* <td>{item.site_address}</td> */}
                              <td>
                                <div className="d-flex">
                                  {item.assigned_to === null ? (
                                    <span
                                      className="badge fs-2 text-bg-danger card-hover rounded-circle round-40 d-flex align-items-center justify-content-center"
                                      title="Not Assigned"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <b>NO</b>
                                    </span>
                                  ) : (
                                    <button
                                      title={item.employee_name}
                                      className={`text-white fs-3 rounded-circle round-40 me-n2 card-hover border border-2 d-flex align-items-center justify-content-center ${getRandomColorClass()}`}
                                    >
                                      <b>{getInitials(item.employee_name)}</b>
                                    </button>
                                  )}
                                </div>
                              </td>
                              <td>
                                {(() => {
                                  if (item.status === "Pending") {
                                    return (
                                      <span className="badge bg-warning badge-width-status">
                                        Pending
                                      </span>
                                    );
                                  } else if (item.status === "Done") {
                                    return (
                                      <span className="badge  bg-success badge-width-status">
                                        Done
                                      </span>
                                    );
                                  } else if (item.status === "In-progress") {
                                    return (
                                      <span className="badge  bg-info badge-width-status">
                                        In-progress
                                      </span>
                                    );
                                  } else if (item.status === "Under Design") {
                                    return (
                                      <span className="badge  bg-primary badge-width-status">
                                        Under Design
                                      </span>
                                    );
                                  } else if (item.status === "Under Revision") {
                                    return (
                                      <span className="badge  bg-secondary badge-width-status">
                                        Under Revision
                                      </span>
                                    );
                                  }
                                })()}
                              </td>
                              <td>
                                <Link
                                  to={`/admin/job-detail/${item.id}`}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  data-bs-original-title="View Enquiry"
                                  // type="button"
                                  className="btn mb-1 btn-primary btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                                >
                                  <i className="fs-5 ti ti-eye"></i>
                                </Link>
                                {/* <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  data-bs-original-title="Download Invoice"
                                  className="btn mb-1 btn-success btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                                >
                                  <i className="fs-5 ti ti-file-invoice"></i>
                                </button> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td colSpan={11}>No data or Loading Data...</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Add Enployee Model */}
          {/* {close && (
            <div
              id="add-employee"
              className="modal fade"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-scrollable modal-lg">
                <div className="modal-content p-3">
                  <div className="modal-header d-flex align-items-center border-bottom">
                    <h4 className="modal-title" id="myModalLabel">
                     New Enquiry
                    </h4>
                    <button
                      type="button"
                      className="btn-close text-white"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form className="form-horizontal form-material">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="tb-pwd">
                              Name
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={employeeData.name}
                              onChange={handleOnchange}
                              className="form-control"
                              placeholder="Enter Name here"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="tb-pwd">
                              Email Address
                            </label>
                            <input
                              type="email"
                              name="email"
                              value={employeeData.email}
                              onChange={handleOnchange}
                              className="form-control"
                              placeholder="Enter email here"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label className="form-label" htmlFor="tb-pwd">
                            Mobile No.
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text contactGroup"
                              id="basic-addon1"
                            >
                              +91
                            </span>
                            <input
                              type="tel"
                              name="mobile"
                              value={employeeData.mobile}
                              onChange={handleOnchange}
                              className="form-control"
                              placeholder="Enter mobile here"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Password</label>
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              value={employeeData.password}
                              onChange={handleOnchange}
                              placeholder="Enter password here"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Confirm Password
                            </label>
                            <input
                              className="form-control"
                              type="password"
                              name="confirmPassword"
                              value={employeeData.confirmPassword}
                              onChange={handleOnchange}
                              placeholder="Enter confirm password here"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-check mt-1">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="status"
                              value=""
                              id="flexCheckChecked"
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Keep this employee account active.?
                            </label>
                            <input type="hidden" name="status" value={status} />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      onClick={handleAddEmployee}
                      className="btn btn-success waves-effect"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger waves-effect"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default Main;
