import React from "react";
import { Link } from "react-router-dom";

// Images
import errorImage from "../../assets/images/backgrounds/errorimg.svg";

const Error = () => {
  return (
    <>
      <div class="position-relative overflow-hidden min-vh-100 d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center justify-content-center w-100">
          <div class="row justify-content-center w-100">
            <div class="col-lg-4">
              <div class="text-center">
                <img src={errorImage} alt="" class="img-fluid" width="370" />
                <h1 class="fw-semibold mb-3 fs-9">Opps!!!</h1>
                <h4 class="fw-semibold mb-3">
                  This page you are looking for could not be found.
                </h4>
                <Link class="btn btn-primary" to="/login" role="button">
                  Go Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
