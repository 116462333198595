import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../admin/customer_profile.css";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";

// Images
import userProfile from "../../assets/images/profile/user-1.jpg";

const EmployeeMain = () => {
  // const [data, setData] = useState([]);
  const [enqData, setEnqData] = useState([]);
  const userDetails = sessionStorage.getItem("EmployeeInfo");
  const useInfo = JSON.parse(userDetails);

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("EmployeeInfo") == null) {
      navigate("/employee_login");
    }
  }, [navigate]);

  // const handleDashboardData = async () => {
  //   try {
  //     const response = await axios.get(
  //       API_BASE_URL + "api/admin/dashboard_data",
  //       {
  //         auth: {
  //           username: API_AUTH_USERNAME,
  //           password: API_AUTH_PASSWORD,
  //         },
  //       }
  //     );
  //     console.log("dashboard data response", response.data);
  //     console.log("Session values:", useInfo);
  //     setData(response.data.data);
  //   } catch (error) {
  //     console.log(error.response.data.message);
  //   }
  // };

  // Fetch latest Enquiries
  const handleEnquiryData = async () => {
    const finalData = {
      user_id: useInfo.id,
      page: 1,
      page_limit: 5,
    };
    console.log("finaldata employee", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/employee_enquiries_list",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("Employee Enquiry data response", response.data);
      // setEnqData(response.data.data);
      setEnqData(response.data.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    handleEnquiryData();
    // handleDashboardData();
  }, []);

  const formattedDate = (date) => {
    const myDate = new Date(date);
    return `${String(myDate.getDate()).padStart(2, "0")}-${String(
      myDate.getMonth() + 1
    ).padStart(2, "0")}-${myDate.getFullYear()}`;
  };

  function getInitials(name) {
  if (!name || typeof name !== 'string') {
    return '';
  }

  const parts = name.trim().split(" ");
  const initials = parts.map((part) => part.charAt(0)).join("");
  return initials.toUpperCase();
}


  function getRandomColorClass() {
    const colors = [
      "bg-info",
      "bg-success",
      "bg-secondary",
      "bg-warning",
      "bg-primary",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }

  const pendingInquiries = enqData.filter(
    (inquiry) => inquiry.status === "Pending"
  );

  // Count of inquiries with status 'Done' & 'Pending'
  const pendingInquiriesCount = pendingInquiries.length;

  const servedInquiries = enqData.filter(
    (inquiry) => inquiry.status === "Done"
  );

  // Get today's date
  const today = new Date();
  const todayDate = today.toISOString().slice(0, 10); // Get YYYY-MM-DD format of today's date

  // Filter the array to get elements with the same date as today
  const todayData = enqData.filter((item) => {
    const itemDate = item.updated_at.split(" ")[0]; // Get the date part of the 'created_at' string
    return itemDate === todayDate; // Compare with today's date
  });

  return (
    <>
      <Sidebar />
      <div className="body-wrapper">
        <Header />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center gap-4 mb-4">
                <div className="position-relative">
                  <div className="border border-2 border-primary rounded-circle">
                    <img
                      src={userProfile}
                      className="rounded-circle m-1"
                      alt="user1"
                      width="60"
                    />
                  </div>
                </div>
                <div>
                  <h3 className="fw-semibold">
                    Hi, <span className="text-dark">{useInfo?.name}</span>
                  </h3>
                  <span>Welcome to the Employee dashboard.</span>
                </div>
              </div>
              <div className="card">
                <div className="border-top"></div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-primary shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-file-description text-primary"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-primary mt-1 mb-1">
                            Total Enquiries
                            <hr className="mt-2 mb-2 text-primary" />
                          </p>
                          <h4 className="fw-semibold text-primary mb-0">
                            {enqData.length || 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-warning shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-users text-warning"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-warning mt-1 mb-1">
                            Today Enquiries
                            <hr className="mt-2 mb-2 text-warning" />
                          </p>
                          <h4 className="fw-semibold text-warning mb-0">
                            {todayData.length}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-primary shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-files text-primary"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-primary mt-1 mb-1">
                            Completed Enquiries
                            <hr className="mt-2 mb-2 text-primary" />
                          </p>
                          <h4 className="fw-semibold text-primary mb-0">
                            {servedInquiries.length || 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-danger shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-file-description text-danger"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-danger mt-1 mb-1">
                            Pending Enquiries
                            <hr className="mt-2 mb-2 text-danger" />
                          </p>
                          <h4 className="fw-semibold text-danger mb-0">
                            {pendingInquiriesCount}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* List of Enquiris */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="fw-semibold mb-3">
                    Recent Assigned Enquiries
                  </h4>
                  <hr />
                  <div className="table-responsive">
                    <table
                      id="custEnquiry"
                      className="table border table-striped table-bordered text-nowrap m-t-30 table-hover"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          {useInfo.employee_type === "normal" && (
                            <th>Company Name</th>
                          )}
                          {useInfo.employee_type === "normal" && (
                            <th>User Name</th>
                          )}
                          {/* <th>Payment</th> */}
                          {/* <th>Amount Paid</th> */}
                          <th>Enquiry Type</th>
                          <th>Enquiry Date</th>
                          {/* <th>Site Address</th> */}
                          <th>Assigned</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {enqData.length > 0 ? (
                          enqData.map((item, index) => {
                            return (
                              <tr key={index} className={getRandomColorClass()}>
                                <td>{index + 1}</td>
                                {useInfo.employee_type === "normal" && (
                                  <td>
                                    {item.company_name.length > 15
                                      ? `${item.company_name.slice(0, 30)}...`
                                      : item.company_name}
                                  </td>
                                )}
                                {useInfo.employee_type === "normal" && (
                                  <td>{item.customer_name}</td>
                                )}

                                {/* <td>
                                  {item.payment_id === null ? (
                                    <span className="badge fs-2 rounded-pill text-bg-warning">
                                      <b>Pending</b>
                                    </span>
                                  ) : (
                                    <span className="badge fs-2 rounded-pill text-bg-success">
                                      <b>Paid</b>
                                    </span>
                                  )}
                                </td> */}
                                {/* <td>{item.amount}</td> */}
                                <td>
                                  {item.is_revised === "N" ? (
                                    <span className="badge fs-2 text-bg-primary badge-width">
                                      <b>New</b>
                                    </span>
                                  ) : (
                                    <span className="badge fs-2 text-bg-secondary badge-width">
                                      <b>Revised</b>
                                    </span>
                                  )}
                                </td>
                                <td>{formattedDate(item.created_at)}</td>
                                {/* <td>{item.site_address}</td> */}
                                <td>
                                  <div className="d-flex">
                                    {item.assigned_to === null ? (
                                      <span
                                        className="badge fs-2 text-bg-danger card-hover rounded-circle round-40 d-flex align-items-center justify-content-center"
                                        title="Not Assigned"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <b>NO</b>
                                      </span>
                                    ) : (
                                      <button
                                        title={item.employee_name}
                                        className={`text-white fs-3 rounded-circle round-40 me-n2 card-hover border border-2 d-flex align-items-center justify-content-center ${getRandomColorClass()}`}
                                      >
                                        <b>{getInitials(item.employee_name)}</b>
                                      </button>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  {(() => {
                                    if (item.status === "Pending") {
                                      return (
                                        <span className="badge bg-warning badge-width-status">
                                          Pending
                                        </span>
                                      );
                                    } else if (item.status === "Done") {
                                      return (
                                        <span className="badge  bg-success badge-width-status">
                                          Done
                                        </span>
                                      );
                                    } else if (item.status === "In-progress") {
                                      return (
                                        <span className="badge  bg-info badge-width-status">
                                          In-progress
                                        </span>
                                      );
                                    } else if (item.status === "Under Design") {
                                      return (
                                        <span className="badge  bg-primary badge-width-status">
                                          Under Design
                                        </span>
                                      );
                                    } else if (
                                      item.status === "Under Revision"
                                    ) {
                                      return (
                                        <span className="badge  bg-secondary badge-width-status">
                                          Under Revision
                                        </span>
                                      );
                                    }
                                  })()}
                                </td>
                                <td>
                                  <Link
                                    to={`/employee/job-detail/${item.id}`}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-original-title="View Enquiry"
                                    // type="button"
                                    className="btn mb-1 btn-primary btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                                  >
                                    <i className="fs-5 ti ti-eye"></i>
                                  </Link>
                                  {/* <button
                                    type="button"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-original-title="Download Invoice"
                                    className="btn mb-1 btn-success btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                                  >
                                    <i className="fs-5 ti ti-file-invoice"></i>
                                  </button> */}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="text-center">
                            <td colSpan={11}>No data or Loading Data...</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeMain;
