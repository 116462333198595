import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import axios from "axios";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "./Pagination";

const Customer = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState(null);
  const [close, setClose] = useState(true);
  const [customerData, setCustomerData] = useState({
    name: "",
    email: "",
    mobile: "",
    area: "",
  });

  const navigate = useNavigate();

  // page navigation section

  const pageSize = 10; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  // pagination onchange
  const onPageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  useEffect(() => {
    if (sessionStorage.getItem("info") == null) {
      navigate("/");
    }
  }, [navigate]);

  const handleOnchange = (e) => {
    setCustomerData({ ...customerData, [e.target.name]: e.target.value });
  };

  // customer data

  const handleCustomerData = async () => {
    const finalData = { page: currentPage, page_limit: pageSize };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/customer_list",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("customer data response", response.data);
      setData(response.data.data);
      setPageCount(response.data.other_data.np_of_pages);
      setTotalCount(response.data.other_data.customer_count);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    handleCustomerData();
  }, [currentPage]);

  // when change details
  const handleRefresh = async () => {
    await handleCustomerData();
  };

  // customer details by id
  const handleCustomerDetails = async (id) => {
    const finalData = { customer_id: id };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/customer_details",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("customer details response", response.data);
      setCustomerData({
        name: response.data.data.name,
        email: response.data.data.email,
        mobile: response.data.data.mobile,
        area: response.data.data.area,
      });
      setId(response.data.data.id);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // validation
  const handleValidation = () => {
    let valid = true;
    // Email format validation
    const regex = /^[A-Za-z ]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (customerData.name === "") {
      toast.error("Name is required!");
      valid = false;
    } else if (!regex.test(customerData.name)) {
      toast.error("Name is invalid");
      valid = false;
    } else if (customerData.email === "") {
      toast.error("Email is required!");
      valid = false;
    } else if (!emailRegex.test(customerData.email)) {
      toast.error("Invalid email format");
      valid = false;
    } else if (customerData.mobile === "") {
      toast.error("Mobile number is required!");
      valid = false;
    } else if (customerData.mobile.length !== 10) {
      toast.error("Mobile number must be at least 10 digits long.");
      valid = false;
    } else if (customerData.area === "") {
      toast.error("Area is required!");
      valid = false;
    }
    return valid;
  };

  // update customer
  const handleUpdateCustomer = async (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }
    try {
      const { name, email, mobile, area } = customerData;

      const finalData = {
        customer_id: id,
        name: name,
        email: email,
        mobile: mobile,
        area: area,
      };
      console.log("update customer finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/admin/edit_customer",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        console.log("update customer response", response.data);
        toast.success(response.data.message);
        setTimeout(() => {
          setClose(false);
          window.location.reload();
          handleRefresh();
        }, 2000);
      } else {
        toast.error(response.data.message);
        setClose(true);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  console.log("close response", close);

  const formattedDate = (date) => {
    const myDate = new Date(date);
    return `${String(myDate.getDate()).padStart(2, "0")}-${String(
      myDate.getMonth() + 1
    ).padStart(2, "0")}-${myDate.getFullYear()}`;
  };
  return (
    <>
      <Sidebar />
      <div className="body-wrapper">
        <Header />
        {/* Start from here */}
        <div className="container-fluid">
          <div className="card bg-light-info shadow-none position-relative overflow-hidden">
            <div className="card-body px-4 py-3">
              <div className="row align-items-center">
                <div className="col-12 col-md-8 col-lg-9 border-bottom-lg">
                  <h4 className="fw-semibold mb-0 d-flex align-items-center gap-2 ">
                    Customers Data{" "}
                    <span className="badge bg-secondary square-pill">
                      {totalCount}
                    </span>{" "}
                  </h4>
                  <p className="mb-0">You can manage customers from here..</p>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link className="text-muted " to="/admin">
                          Dashboard
                        </Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Customers
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-end">
                    {/* <button
                      type="button"
                      className="btn btn-info btn-rounded m-t-10 mb-2 me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#add-customer"
                    >
                      <i className="fs-3 ti ti-plus"></i> Add Customer
                    </button> */}
                  </div>
                  <div
                    id="add-customer"
                    className="modal fade in"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-scrollable modal-lg">
                      <div className="modal-content p-3">
                        <div className="modal-header d-flex align-items-center border-bottom">
                          <h4 className="modal-title" id="myModalLabel">
                            Add New Customer
                          </h4>
                          <button
                            type="button"
                            className="btn-close text-white"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body mt-2">
                          <form className="form-horizontal form-material">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="tb-pwd"
                                  >
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Name here"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="tb-pwd"
                                  >
                                    Email Address
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter email here"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <label className="form-label" htmlFor="tb-pwd">
                                  Mobile No.
                                </label>
                                <div className="input-group mb-3">
                                  <span
                                    className="input-group-text contactGroup"
                                    id="basic-addon1"
                                  >
                                    +91
                                  </span>
                                  <input
                                    type="tel"
                                    className="form-control"
                                    placeholder="Enter mobile here"
                                    aria-describedby="basic-addon1"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">Gender</label>
                                  <select className="form-select">
                                    <option
                                      value=""
                                      disabled
                                      className="text-muted"
                                    >
                                      Select your gender
                                    </option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                  </select>
                                  {/* <small className="form-control-feedback">
                                    Select your gender
                                  </small> */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Date of Birth
                                  </label>
                                  <input type="date" className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Other Category
                                  </label>
                                  <select className="form-select">
                                    <option
                                      value=""
                                      disabled
                                      className="text-muted"
                                    >
                                      Select value
                                    </option>
                                    <option value="">Demo 1</option>
                                    <option value="">Demo 2</option>
                                    <option value="">Demo 3</option>
                                  </select>
                                  {/* <small className="form-control-feedback">
                                    Select your gender
                                  </small> */}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="tb-pwd"
                                  >
                                    Address
                                  </label>
                                  <textarea
                                    className="form-control"
                                    rows="2"
                                    placeholder="Enter address here"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className="btn btn-success waves-effect"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger waves-effect"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {close && (
                    <div
                      id="edit-customer"
                      className="modal fade in"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable modal-lg">
                        <div className="modal-content p-3">
                          <div className="modal-header d-flex align-items-center border-bottom">
                            <h4 className="modal-title" id="myModalLabel">
                              Edit Customer
                            </h4>
                            <button
                              type="button"
                              className="btn-close text-white"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body mt-2">
                            <form
                              className="form-horizontal form-material"
                              onSubmit={handleUpdateCustomer}
                            >
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="tb-pwd"
                                    >
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      name="name"
                                      value={customerData.name}
                                      onChange={handleOnchange}
                                      className="form-control"
                                      placeholder="Enter Name here"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="tb-pwd"
                                    >
                                      Email Address
                                    </label>
                                    <input
                                      type="email"
                                      name="email"
                                      value={customerData.email}
                                      onChange={handleOnchange}
                                      className="form-control"
                                      placeholder="Enter email here"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <label
                                    className="form-label"
                                    htmlFor="tb-pwd"
                                  >
                                    Mobile No.
                                  </label>
                                  <div className="input-group mb-3">
                                    <span
                                      className="input-group-text contactGroup"
                                      id="basic-addon1"
                                    >
                                      +91
                                    </span>
                                    <input
                                      type="tel"
                                      name="mobile"
                                      value={customerData.mobile}
                                      onChange={handleOnchange}
                                      className="form-control"
                                      placeholder="Enter mobile here"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">Area</label>
                                    <input
                                      type="text"
                                      name="area"
                                      value={customerData.area}
                                      onChange={handleOnchange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="modal-footer">
                                <button
                                  type="submit"
                                  className="btn btn-success waves-effect"
                                >
                                  Submit
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-danger waves-effect"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="table-responsive">
                    <table
                      id="demo-foo-addrow"
                      className="
                      table border table-striped table-bordered text-nowrap m-t-30 table-hover
                      contact-list
                      "
                      data-paging="true"
                      data-paging-size="7"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Mobile No.</th>
                          <th>Area</th>
                          <th>Customer Since</th>
                          {/* <th>Status</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          data.map((ele, index) => {
                            const currentIndex =
                              (currentPage - 1) * 10 + index + 1;
                            return (
                              <tr key={ele.id}>
                                <td>{currentIndex}</td>
                                <td>
                                  <Link
                                    to={`/admin/customer-profile/${ele.id}`}
                                    className="link"
                                  >
                                    {ele.name}
                                  </Link>
                                </td>
                                <td>{ele.email}</td>
                                <td>
                                  <a href={`tel:${ele.mobile}`}>{ele.mobile}</a>
                                </td>
                                <td>{ele.area}</td>
                                <td>{formattedDate(ele.customer_since)}</td>
                                {/* <td>
                              <span className="badge bg-success">Active</span>
                            </td> */}
                                <td className="text-center">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleCustomerDetails(ele.id)
                                    }
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit-customer"
                                    className="btn mb-1 btn-warning btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                                  >
                                    <i className="fs-5 ti ti-edit"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="text-center">
                            <td colSpan={6}>No data or Loading Data...</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {data.length > 0 && (
                      <div>
                        <Pagination
                          currentPage={currentPage}
                          totalPages={pageCount}
                          onPageChange={onPageChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </>
  );
};

export default Customer;
