import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";

import "./customer_profile.css";
import { FaFilePdf } from "react-icons/fa6";
const JobDetail = () => {
  const [job, setJob] = useState([]);
  const { jobId } = useParams();
  const [empData, setEmpData] = useState([]);
  const [declineNote, setDeclineNote] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // job details by id
  const fetchJobDetails = async () => {
    const finalData = { enquiry_id: jobId };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/enquiry_details",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        console.log("job details response", response.data);
        const data = response.data.data;
        setJob(data);
        setDeclineNote(response.data.data.design_note || "");
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchJobDetails();
  }, [jobId]);

  // fetching employee data
  const handleEmployeeData = async () => {
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/employee_list",
        null,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("employee data response", response.data);
      setEmpData(response.data.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    handleEmployeeData();
  }, []);

  // Assign emp to enquiry
  const handleAssignEmpToEnq = async (e) => {
    e.preventDefault();
    const selectedEmployeeId = e.target.employeeId.value;
    const finalData = { employee_id: selectedEmployeeId, enquiry_id: jobId };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/assign_enquiry_to_employee",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        console.log("employee data response", response.data);
        toast.success(response.data.message);
        handleRefresh();
      }
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  // Assign emp to enquiry
  const handleStatusUpdate = async (e) => {
    e.preventDefault();
    const jobStatus = e.target.jobStatus.value;
    const finalData = { status: jobStatus, enquiry_id: jobId };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/update_enquiry_status",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("Update Status data response", response.data);
      toast.success(response.data.message);
      handleRefresh();
      setTimeout(() => {
        window.location.reload();
      }, 1200); // Adjust the delay as needed
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const handleRefreshData = async () => {
    await fetchJobDetails();
  };

  // update status design image accept or decline
  const handleUpdateStatus = async (e, action) => {
    e.preventDefault();
    if (action === "N" && declineNote === "") {
      toast.error("Decline note required for decline image.");
      return;
    }
    const finalData = {
      enquiry_id: jobId,
      status: action,
      design_note: action === "Y" ? "" : declineNote,
    };
    console.log("Update design Status final data", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/update_enquiry_design_status",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("Update design Status data response", response.data);
      toast.success(response.data.message);
      handleRefreshData();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1200);
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  // when change details
  const handleRefresh = async () => {
    await handleAssignEmpToEnq();
    await handleStatusUpdate();
  };

  const formattedDate = (date) => {
    const myDate = new Date(date);
    return `${String(myDate.getDate()).padStart(2, "0")}-${String(
      myDate.getMonth() + 1
    ).padStart(2, "0")}-${myDate.getFullYear()}`;
  };

  const getExtensionFromUrl = (url) => {
    if (typeof url === "string") {
      const lastIndex = url.lastIndexOf(".");
      return url.slice(lastIndex + 1);
    } else {
      // Handle the case when url is undefined
      return null; // or any other appropriate action
    }
  };

  return (
    <>
      <Sidebar />
      <div className="body-wrapper">
        <Header />
        {/* Start from here */}
        <div className="container-fluid">
          {/* <Link to="/admin/enquiry" className="btn btn-info waves-effect mb-2">
            Back to Enquiries
          </Link> */}
          <div className="card bg-light-info pb-0 mb-0 shadow-none position-relative overflow-hidden">
            <div className="card-body px-4 py-3">
              <div className="row align-items-center">
                <div className="col-12 col-md-8 col-lg-9 border-bottom-lg">
                  <h4 className="fw-semibold mb-0">
                    Job Detail - #{jobId} | Status - {job.status}
                  </h4>
                  <p className="mb-0">
                    You can view & manage specific job from here..
                  </p>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link className="text-muted " to="/admin">
                          Dashboard
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link className="text-muted " to="/admin/enquiry">
                          Enquiries
                        </Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Job #{jobId}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-content pt-3" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-job"
              role="tabpanel"
              aria-labelledby="pills-job-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="card shadow-none border">
                    <div className="card-body">
                      {/* <div className="d-flex justify-content-end">
                        <Link
                          className="btn btn-success btn-rounded m-t-10 mb-2"
                          to=""
                        >
                          <i className="fs-5 ti ti-file-invoice"></i> Download
                          Invoice
                        </Link>
                      </div> */}
                      {/* Assign Enquiry */}
                      <h4 className="fw-semibold mt-3">
                        Assign Enquiry to employee & Update status
                      </h4>
                      <hr />
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <form
                            className="form-horizontal form-material mt-2"
                            onSubmit={handleAssignEmpToEnq}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Assign Enquiry
                                  </label>
                                  <select
                                    className="form-select"
                                    name="employeeId"
                                  >
                                    <option
                                      value=""
                                      selected
                                      disabled
                                      className="text-muted"
                                    >
                                      Select Employee Name
                                    </option>
                                    {empData.map((emp, index) => (
                                      <option
                                        key={index}
                                        value={emp.id}
                                        selected={
                                          job.assigned_to === emp.id ||
                                          job.employee_name === emp.name
                                        }
                                      >
                                        {emp.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-success waves-effect"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="col-md-6 col-12">
                          <form
                            className="form-horizontal form-material mt-2"
                            onSubmit={handleStatusUpdate}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Enquiry Status
                                  </label>
                                  <select
                                    className="form-select"
                                    name="jobStatus"
                                  >
                                    <option
                                      value=""
                                      selected
                                      disabled
                                      className="text-muted"
                                    >
                                      Select enquiry status
                                    </option>
                                    <option
                                      value="Pending"
                                      selected={job.status === "Pending"}
                                    >
                                      Pending
                                    </option>
                                    <option
                                      value="Done"
                                      selected={job.status === "Done"}
                                    >
                                      Done
                                    </option>
                                    <option
                                      value="In-progress"
                                      selected={job.status === "In-progress"}
                                    >
                                      In-progress
                                    </option>
                                    <option
                                      value="Under Design"
                                      selected={job.status === "Under Design"}
                                    >
                                      Under Design
                                    </option>
                                    <option
                                      value="Under Revision"
                                      selected={job.status === "Under Revision"}
                                    >
                                      Under Revision
                                    </option>
                                  </select>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-success waves-effect"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      {/* Job Details */}
                      <h4 className="fw-semibold mt-3">Job Details</h4>
                      <hr />
                      <form className="form-horizontal form-material mt-4">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Company Name
                              </label>
                              <input
                                type="text"
                                value={job.company_name}
                                defaultValue="svk consultant"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Customer Name
                              </label>
                              <input
                                type="text"
                                value={job.customer_name}
                                defaultValue="Rohit Patil"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Address
                              </label>
                              <input
                                type="text"
                                value={job.address}
                                defaultValue="Katraj,Pune"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Site Name
                              </label>
                              <input
                                type="text"
                                value={job.site_name}
                                defaultValue="Vk Apartment"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Site Address
                              </label>
                              <input
                                type="text"
                                value={job.site_address}
                                defaultValue="Near Shivraj Hotel"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Shaft Type
                              </label>
                              <input
                                type="text"
                                value={job.shaft_type}
                                defaultValue="Brick"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Shaft Width (mm)
                              </label>
                              <input
                                type="text"
                                value={job.shaft_width}
                                defaultValue="25mm"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Shaft Depth (mm)
                              </label>
                              <input
                                type="text"
                                value={job.shaft_depth}
                                defaultValue="25mm"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Shaft Pit (mm)
                              </label>
                              <input
                                type="text"
                                value={job.shaft_pit}
                                defaultValue="25mm"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Shaft Travel (mm)
                              </label>
                              <input
                                type="text"
                                value={job.shaft_travel}
                                defaultValue="25mm"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Shaft Height
                              </label>
                              <input
                                type="text"
                                value={job.shaft_heights}
                                defaultValue="25ft"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Overhead (mm)
                              </label>
                              <input
                                type="text"
                                value={job.overhead}
                                defaultValue="25mm"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                No. of Floors
                              </label>
                              <input
                                type="text"
                                value={job.no_of_floors}
                                defaultValue="5"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                No. of Openings
                              </label>
                              <input
                                type="text"
                                value={job.no_of_opening}
                                defaultValue="5"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                No. of Stops
                              </label>
                              <input
                                type="text"
                                value={job.no_of_stop}
                                defaultValue="5"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Dummy Landing
                              </label>
                              <input
                                type="text"
                                value={job.dummy_landing}
                                defaultValue="5"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Opposite/ Adjacent Opening at
                              </label>
                              <input
                                type="text"
                                value={job.opposite_adjacent_opening_at}
                                defaultValue="5"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Floor Designation
                              </label>
                              <input
                                type="text"
                                value={job.floor_designation}
                                defaultValue="5"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                No. of Person Capacity / Capacity in (Kg)
                              </label>
                              <input
                                type="text"
                                value={job.no_of_person_or_kg}
                                defaultValue="3"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Type of Lift
                              </label>
                              <input
                                type="text"
                                value={job.lift_type}
                                defaultValue="Cargo Lift"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Opening Type
                              </label>
                              <input
                                type="text"
                                value={job.opening_type}
                                defaultValue="Adjacent Side"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Machine Make
                              </label>
                              <input
                                type="text"
                                value={job.machine_make}
                                defaultValue="Adjacent Side"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Machine Type
                              </label>
                              <input
                                type="text"
                                value={job.machine_type}
                                defaultValue="Geared"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Cabin Size Width (mm)
                              </label>
                              <input
                                type="text"
                                value={job.cabin_width}
                                defaultValue="35"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Speed (m/s)
                              </label>
                              <input
                                type="text"
                                defaultValue={job.speed}
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Cabin Size Depth (mm)
                              </label>
                              <input
                                type="text"
                                value={job.cabin_depth}
                                defaultValue="65"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Cabin Size Height (mm)
                              </label>
                              <input
                                type="text"
                                value={job.cabin_height}
                                defaultValue="45"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Door Frame
                              </label>
                              <input
                                type="text"
                                value={job.door_frame}
                                defaultValue="On Beam"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Car Door Type
                              </label>
                              <input
                                type="text"
                                value={job.car_door_type}
                                defaultValue="Automatic"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Landing Door Type
                              </label>
                              <input
                                type="text"
                                value={job.landing_door_type}
                                defaultValue="Automatic"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Clear Opening Required (mm)
                              </label>
                              <input
                                type="text"
                                value={job.clear_opening_required}
                                defaultValue="75"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Door Make
                              </label>
                              <input
                                type="text"
                                value={job.door_make}
                                defaultValue="Fermator"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Door Lock Position
                              </label>
                              <input
                                type="text"
                                defaultValue={job.door_lock_position}
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Mechanism/CWT Position
                              </label>
                              <input
                                type="text"
                                value={job.mechanism_cwt_position}
                                defaultValue="Back Side"
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Note
                              </label>
                              <textarea
                                type="text"
                                value={job.note}
                                defaultValue="Something here."
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Job Creation Date
                              </label>
                              <input
                                type="text"
                                value={formattedDate(job.created_at)}
                                defaultValue="Something here."
                                className="form-control jb_border"
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Enquiry Design Image
                              </label>
                              <br />
                              {job.design_image ? (
                                getExtensionFromUrl(job.design_image) ===
                                "pdf" ? (
                                  <Link to={job.design_image} target="_blank">
                                    <div className="pdf_div">
                                      <FaFilePdf />{" "}
                                    </div>{" "}
                                  </Link>
                                ) : (
                                  <Link to={job.design_image} target="_blank">
                                    <div className="design_img_div">
                                      <img
                                        src={job.design_image}
                                        className="img-fluid custom_img_design"
                                        alt="Not available"
                                      />
                                    </div>
                                  </Link>
                                )
                              ) : (
                                <span>Not available</span>
                              )}
                              {(job.is_design_image_approved === "N" ||
                                job.is_design_image_approved === "") &&
                                job.design_image !== null && (
                                  <>
                                    <div className="mt-3">
                                      <label
                                        className="form-label"
                                        htmlFor="declineNote"
                                      >
                                        Decline Note:
                                      </label>
                                      <input
                                        type="text"
                                        value={declineNote}
                                        onChange={(e) =>
                                          setDeclineNote(e.target.value)
                                        }
                                        className="form-control jb_border"
                                      />
                                    </div>
                                    <div className="d-flex gap-3 mt-3">
                                      <button
                                        className="btn btn-success waves-effect"
                                        onClick={(e) =>
                                          handleUpdateStatus(e, "Y")
                                        }
                                      >
                                        Accept
                                      </button>
                                      <button
                                        className="btn btn-danger waves-effect"
                                        onClick={(e) =>
                                          handleUpdateStatus(e, "N")
                                        }
                                      >
                                        Decline
                                      </button>
                                    </div>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      </form>

                      {/* Payment Details */}
                      <h4 className="fw-semibold mt-3">Payment Details</h4>
                      <hr />
                      <form className="form-horizontal form-material mt-4">
                        {(job.is_revised === "N" || job.is_revised === "Y") && (
                          <div className="row">
                            <div className="col-md-3">
                              <div className="mb-3">
                                <label className="form-label" htmlFor="tb-pwd">
                                  Payment
                                </label>
                                <input
                                  type="text"
                                  value={
                                    job.payment_id === null ? "Pending" : "Paid"
                                  }
                                  className="form-control jb_border"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="mb-3">
                                <label className="form-label" htmlFor="tb-pwd">
                                  Amount Paid
                                </label>
                                <input
                                  type="text"
                                  value={job.amount}
                                  className="form-control jb_border"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label" htmlFor="tb-pwd">
                                  Payment Id
                                </label>
                                <input
                                  type="text"
                                  value={job.payment_id}
                                  className="form-control jb_border"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {job.is_revised === "Y" && (
                          <div className="row">
                            <div className="col-md-3">
                              <div className="mb-3">
                                <label className="form-label" htmlFor="tb-pwd">
                                  Revised Payment
                                </label>
                                <input
                                  type="text"
                                  value={
                                    job.revised_payment_id === null
                                      ? "Pending"
                                      : "Paid"
                                  }
                                  className="form-control jb_border"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="mb-3">
                                <label className="form-label" htmlFor="tb-pwd">
                                  Revised Amount Paid
                                </label>
                                <input
                                  type="text"
                                  value={job.revised_amount}
                                  className="form-control jb_border"
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label" htmlFor="tb-pwd">
                                  Revised Payment Id
                                </label>
                                <input
                                  type="text"
                                  value={job.revised_payment_id}
                                  className="form-control jb_border"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </form>

                      <h4 className="fw-semibold mt-3">
                        Assign Enquiry to employee & Update status
                      </h4>
                      <hr />
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <form
                            className="form-horizontal form-material mt-2"
                            onSubmit={handleAssignEmpToEnq}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Assign Enquiry
                                  </label>
                                  <select
                                    className="form-select"
                                    name="employeeId"
                                  >
                                    <option
                                      value=""
                                      selected
                                      disabled
                                      className="text-muted"
                                    >
                                      Select Employee Name
                                    </option>
                                    {empData.map((emp, index) => (
                                      <option
                                        key={index}
                                        value={emp.id}
                                        selected={
                                          job.assigned_to === emp.id ||
                                          job.employee_name === emp.name
                                        }
                                      >
                                        {emp.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-success waves-effect"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="col-md-6 col-12">
                          <form
                            className="form-horizontal form-material mt-2"
                            onSubmit={handleStatusUpdate}
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Enquiry Status
                                  </label>
                                  <select
                                    className="form-select"
                                    name="jobStatus"
                                  >
                                    <option
                                      value=""
                                      selected
                                      disabled
                                      className="text-muted"
                                    >
                                      Select enquiry status
                                    </option>
                                    <option
                                      value="Pending"
                                      selected={job.status === "Pending"}
                                    >
                                      Pending
                                    </option>
                                    <option
                                      value="Done"
                                      selected={job.status === "Done"}
                                    >
                                      Done
                                    </option>
                                    <option
                                      value="In-progress"
                                      selected={job.status === "In-progress"}
                                    >
                                      In-progress
                                    </option>
                                    <option
                                      value="Under Design"
                                      selected={job.status === "Under Design"}
                                    >
                                      Under Design
                                    </option>
                                    <option
                                      value="Under Revision"
                                      selected={job.status === "Under Revision"}
                                    >
                                      Under Revision
                                    </option>
                                  </select>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-success waves-effect"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade show"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="card shadow-none border">
                    <div className="card-body">
                      <h4 className="fw-semibold mb-3">Profile Details</h4>
                      <hr />
                      <form className="form-horizontal form-material mt-4">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Name here"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Email Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter email here"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="form-label" for="tb-pwd">
                              Mobile No.
                            </label>
                            <div className="input-group mb-3">
                              <span
                                className="input-group-text contactGroup"
                                id="basic-addon1"
                              >
                                +91
                              </span>
                              <input
                                type="tel"
                                className="form-control"
                                placeholder="Enter mobile here"
                                aria-describedby="basic-addon1"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Gender</label>
                              <select className="form-select">
                                <option
                                  value=""
                                  selected
                                  disabled
                                  className="text-muted"
                                >
                                  Select your gender
                                </option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Date of Birth
                              </label>
                              <input type="date" className="form-control" />
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-success waves-effect"
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-followers"
              role="tabpanel"
              aria-labelledby="pills-followers-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="card shadow-none border">
                    <div className="card-body">
                      <h4 className="fw-semibold mb-3">Change Password</h4>
                      <hr />
                      <form className="form-horizontal form-material mt-4">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Current Password
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter current password here"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                New Password
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter new password here"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Confirm New Password
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter confirm password here"
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-success waves-effect"
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </>
  );
};

export default JobDetail;
