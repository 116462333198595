import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const OtpForm = () => {
  const [otp, setOtp] = useState("");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const location = useLocation();
  const { otpData, email, user_type } = location.state;
  const navigate = useNavigate();

  // get otp using email
  const enteredOTP = `${otp}${otp1}${otp2}${otp3}`.trim();
  console.log("otpData", otpData);
  const handleEmailOtp = (e) => {
    e.preventDefault();
    if (enteredOTP === String(otpData)) {
      toast.success("OTP verified successfully");
      setTimeout(()=>{
        navigate("/reset_password", {state:{email:email, user_type:user_type}});
      },2000)
      // Redirect the user to a success page or perform any other necessary action
    } else {
      toast.error("Invalid OTP. Please try again.");
    }
  };

  const otpInputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleOtpChange = (value, index) => {
    // Update the state based on the input value
    switch (index) {
      case 0:
        setOtp(value);
        break;
      case 1:
        setOtp1(value);
        break;
      case 2:
        setOtp2(value);
        break;
      case 3:
        setOtp3(value);
        break;
      default:
        break;
    }
    // Move focus to the next input if a digit is entered
    if (value.length === 1 && index < otpInputRefs.length - 1) {
      otpInputRefs[index + 1].current.focus();
    }
  };

  const handleBackspace = (index, e) => {
    if (e.keyCode === 8 && index > 0 && !e.target.value) {
      // Move focus to the previous input
      otpInputRefs[index - 1].current.focus();
    }
  };
  return (
    <>
      <div class="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center justify-content-center w-100">
          <div class="row justify-content-center w-100">
            <div class="col-md-8 col-lg-6 col-xl-4 col-xxl-3">
              <div class="card mb-0">
                <div class="card-body pt-5">
                  <div class="text-nowrap logo-img text-center d-block mb-4">
                    {/* <img
                      src={darkLogo}
                      width="180"
                      alt=""
                    /> */}
                    <h3>
                      <b>OTP Verification</b>
                    </h3>
                  </div>
                  <div class="mb-3 text-center">
                    <p class="mb-0 ">
                      We've sent a verification code to your email - {email}
                    </p>
                  </div>
                  <form onSubmit={handleEmailOtp}>
                    <div class="mb-3">
                      <label
                        for="exampleInputEmail1"
                        class="form-label w-100 text-center"
                      >
                        Enter OTP Code
                      </label>
                      <div className="input_opt">
                        {[otp, otp1, otp2, otp3].map((otp, index) => (
                          <input
                            key={index}
                            ref={otpInputRefs[index]}
                            type="text"
                            value={otp}
                            onChange={(e) =>
                              handleOtpChange(e.target.value, index)
                            }
                            onKeyDown={(e) => handleBackspace(index, e)}
                            class="form-control otp_input"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            maxLength={1}
                            autoFocus={index === 0}
                          />
                        ))}
                      </div>
                    </div>
                    <button
                      type="submit"
                      class="btn btn-primary w-100 py-8 mb-3"
                    >
                      Verify OTP
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </div>
    </>
  );
};

export default OtpForm;
