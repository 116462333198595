import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";

const ForgetPass = () => {
  const { user_type } = useParams();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  // get otp using email
  const handleEmailOtp = async (e) => {
    e.preventDefault();
    const finalData = { email: email, user_type: user_type };
    console.log("finalData email", finalData);
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/forget_password_send_otp",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        console.log("email otp response", response.data);
        const otpData = response.data.data;
        setTimeout(() => {
          navigate("/otp", {
            state: { otpData: otpData, email: email, user_type: user_type },
          });
        }, 2000);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  return (
    <>
      <div class="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center justify-content-center w-100">
          <div class="row justify-content-center w-100">
            <div class="col-md-8 col-lg-6 col-xl-4 col-xxl-3">
              <div class="card mb-0">
                <div class="card-body pt-5">
                  <div class="text-nowrap logo-img text-center d-block mb-4">
                    {/* <img
                      src={darkLogo}
                      width="180"
                      alt=""
                    /> */}
                    <h3>
                      <b>{user_type.toLocaleUpperCase()} FORGET PASS</b>
                    </h3>
                  </div>
                  <div class="mb-5 text-center">
                    <p class="mb-0 ">
                      Please enter the email address associated with your
                      account and We will email you a link to reset your
                      password.
                    </p>
                  </div>
                  <form onSubmit={handleEmailOtp}>
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <button
                      type="submit"
                      class="btn btn-primary w-100 py-8 mb-3"
                    >
                      Send OTP
                    </button>
                    <Link
                      to={user_type === "admin" ? "/login" : "/employee_login"}
                      class="btn btn-light-primary text-primary w-100 py-8"
                    >
                      Back to Login
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </div>
    </>
  );
};

export default ForgetPass;
