import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const { email, user_type } = location.state;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password === "") {
      toast.error("Password must be required!");
      return;
    } else if (password.length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    } else if (confirmPassword !== password) {
      toast.error("Both Password fields are not the same.");
      return;
    }
    try {
      const finalData = {
        email: email,
        new_password: password,
        user_type: user_type,
      };
      console.log("reset password",finalData)
      const response = await axios.post(
        API_BASE_URL + "api/admin/reset_password",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(()=>{
            navigate(user_type === "admin"  ? "/login" : "/employee_login")
        },2000)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibilityConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="osition-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
      <div className="d-flex align-items-center justify-content-center w-100">
        <div className="row justify-content-center w-100">
          <div className="ol-md-8 col-lg-6 col-xl-4 col-xxl-3">
            <div className="card mt-5">
              <div className="card-body">
                <h3 className="card-title text-center mb-4">Reset Password</h3>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3 password_field">
                    <label htmlFor="password" className="form-label">
                      New Password
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control "
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <buttons
                      type="button"
                      className="password-toggle-button"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <FaEye color="#a1a1a1" />
                      ) : (
                        <FaEyeSlash color="#a1a1a1" />
                      )}
                    </buttons>
                  </div>
                  <div className="mb-3 password_field">
                    <label htmlFor="confirmPassword" className="form-label">
                      Confirm Password
                    </label>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control"
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <buttons
                      type="button"
                      className="password-toggle-button"
                      onClick={togglePasswordVisibilityConfirm}
                    >
                      {showConfirmPassword ? (
                        <FaEye color="#a1a1a1" />
                      ) : (
                        <FaEyeSlash color="#a1a1a1" />
                      )}
                    </buttons>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w-100 py-8 mb-3"
                  >
                    Reset Password
                  </button>
                  <Link
                    to={user_type === "admin"  ? "/login" : "/employee_login"}
                    class="btn btn-light-primary text-primary w-100 py-8"
                  >
                    Back to Login
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
    </div>
  );
};

export default ResetPassword;
