import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";
import Pagination from "./Pagination";
const Enquiry = () => {
  const [data, setData] = useState([]);
  const sessionDataString = sessionStorage.getItem("info");
  const sessionData = JSON.parse(sessionDataString);
  const navigate = useNavigate();

  // page navigation section

  const pageSize = 10; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  // pagination onchange
  const onPageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  useEffect(() => {
    if (sessionStorage.getItem("info") == null) {
      navigate("/");
    }
  }, [navigate]);

  // Fetch enquiry data
  const handleEnquiryData = async () => {
    const finalData = { page: currentPage, page_limit: pageSize };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/enquiries_list",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("Enquiry data response", response.data);
      setData(response.data.data);
      setPageCount(response.data.other_data.np_of_pages);
      setTotalCount(response.data.other_data.customer_count);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    handleEnquiryData();
  }, [currentPage]);

  const formattedDate = (date) => {
    const myDate = new Date(date);
    return `${String(myDate.getDate()).padStart(2, "0")}-${String(
      myDate.getMonth() + 1
    ).padStart(2, "0")}-${myDate.getFullYear()}`;
  };

  function getInitials(name) {
    if (!name || typeof name !== 'string') {
      return '';
    }
  
    const parts = name.trim().split(" ");
    const initials = parts.map((part) => part.charAt(0)).join("");
    return initials.toUpperCase();
  }
  

  function getRandomColorClass() {
    const colors = [
      "bg-info",
      "bg-success",
      "bg-secondary",
      "bg-warning",
      "bg-primary",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }

  return (
    <>
      <Sidebar />
      <div className="body-wrapper">
        <Header />
        {/* Start from here */}
        <div className="container-fluid">
          <div className="card bg-light-info shadow-none position-relative overflow-hidden">
            <div className="card-body px-4 py-3">
              <div className="row align-items-center">
                <div className="col-12 col-md-8 col-lg-9 border-bottom-lg">
                  <h4 className="fw-semibold mb-0 d-flex align-items-center gap-2">
                    Customer Enquiries{" "}
                    <span className="badge bg-secondary square-pill">
                      {totalCount}
                    </span>{" "}
                  </h4>

                  <p className="mb-0">You can manage enquiries from here..</p>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link className="text-muted " to="/admin">
                          Dashboard
                        </Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Enquiry
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      id="custEnquiry"
                      className="table border table-striped table-bordered text-nowrap m-t-30 table-hover"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Company Name</th>
                          <th>User Name</th>
                          {/* <th>Payment</th> */}
                          {/* <th>Amount Paid</th> */}
                          <th>Enquiry Type</th>
                          <th>Enquiry Date</th>
                          {/* <th>Site Address</th> */}
                          <th>Assigned</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          data.map((item, index) => {
                            const currentIndex =
                              (currentPage - 1) * 10 + index + 1;
                            return (
                              <tr key={index} className={getRandomColorClass()}>
                                <td>
                                  <Link to={`/admin/job-detail/${item.id}`}>
                                    {currentIndex}
                                  </Link>
                                </td>
                                <td>
                                  {item.company_name.length > 15
                                    ? `${item.company_name.slice(0, 30)}...`
                                    : item.company_name}
                                </td>
                                <td>
                                  <Link
                                    to={`/admin/customer-profile/${item.user_id}`}
                                  >
                                    {item.customer_name}
                                  </Link>
                                </td>

                                {/* <td>
                                      {item.payment_id === null ? (
                                        <span className="badge fs-2 rounded-pill text-bg-warning">
                                          <b>Pending</b>
                                        </span>
                                      ) : (
                                        <span className="badge fs-2 rounded-pill text-bg-success">
                                          <b>Paid</b>
                                        </span>
                                      )}
                                    </td> */}
                                {/* <td>{item.amount}</td> */}
                                <td>
                                  {item.is_revised === "N" ? (
                                    <span className="badge fs-2 text-bg-primary badge-width">
                                      <b>New</b>
                                    </span>
                                  ) : (
                                    <span className="badge fs-2 text-bg-secondary badge-width">
                                      <b>Revised</b>
                                    </span>
                                  )}
                                </td>
                                <td>{formattedDate(item.created_at)}</td>
                                {/* <td>{item.site_address}</td> */}
                                <td>
                                  <div className="d-flex">
                                    {item.assigned_to === null ? (
                                      <span
                                        className="badge fs-2 text-bg-danger card-hover rounded-circle round-40 d-flex align-items-center justify-content-center"
                                        title="Not Assigned"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <b>NO</b>
                                      </span>
                                    ) : (
                                      <button
                                        title={item.employee_name}
                                        className={`text-white fs-3 rounded-circle round-40 me-n2 card-hover border border-2 d-flex align-items-center justify-content-center ${getRandomColorClass()}`}
                                      >
                                        <b>{getInitials(item.employee_name)}</b>
                                      </button>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  {(() => {
                                    if (item.status === "Pending") {
                                      return (
                                        <span className="badge bg-warning badge-width-status">
                                          Pending
                                        </span>
                                      );
                                    } else if (item.status === "Done") {
                                      return (
                                        <span className="badge  bg-success badge-width-status">
                                          Done
                                        </span>
                                      );
                                    } else if (item.status === "In-progress") {
                                      return (
                                        <span className="badge  bg-info badge-width-status">
                                          In-progress
                                        </span>
                                      );
                                    } else if (item.status === "Under Design") {
                                      return (
                                        <span className="badge  bg-primary badge-width-status">
                                          Under Design
                                        </span>
                                      );
                                    } else if (
                                      item.status === "Under Revision"
                                    ) {
                                      return (
                                        <span className="badge  bg-secondary badge-width-status">
                                          Under Revision
                                        </span>
                                      );
                                    }
                                  })()}
                                </td>
                                <td>
                                  <Link
                                    to={`/admin/job-detail/${item.id}`}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-original-title="View Enquiry"
                                    // type="button"
                                    className="btn mb-1 btn-primary btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                                  >
                                    <i className="fs-5 ti ti-eye"></i>
                                  </Link>
                                  {/* <button
                                    type="button"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-original-title="Download Invoice"
                                    className="btn mb-1 btn-success btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                                  >
                                    <i className="fs-5 ti ti-file-invoice"></i>
                                  </button> */}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="text-center">
                            <td colSpan={11}>No data or Loading Data...</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {data.length > 0 && (
                      <div>
                        <Pagination
                          currentPage={currentPage}
                          totalPages={pageCount}
                          onPageChange={onPageChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Datatable */}
          {/* <section className="datatables">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                  <div className="table-responsive">
                    <table
                      id="demo-foo-addrow"
                      className="table table-bordered m-t-30 table-hover"
                      data-paging="true"
                      data-paging-size="7"
                    >
                      <thead className="text-center">
                          <tr className="text-center">
                            <th>EQID</th>
                            <th>Company Name</th>
                            <th>User Name</th>
                            <th>Adress</th>
                            <th>Payment</th>
                            <th>Amount Paid</th>
                            <th>Mobile</th>
                            <th>Enquiry Type</th>
                            <th>Enquiry Date</th>
                            <th>Address</th>
                            <th>Assigned</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {data.map((item, index) => (
                            <tr>
                              <td>{index+1}</td>
                              <td>{item.company_name}</td>
                              <td>
                                <Link to="/admin/profile">{item.customer_name}</Link>
                              </td>
                              <td>{item.address}</td>
                              <td>
                                <span className="badge fs-2 rounded-pill text-bg-success">
                                  <b>Paid</b>
                                </span>
                              </td>
                              <td>₹ 3450</td>
                              <td>8329007426</td>
                              <td>
                                <span className="badge fs-2 rounded-pill text-bg-primary">
                                  <b>New</b>
                                </span>
                              </td>
                              <td>14/03/2024</td>
                              <td>Narhe, Pune</td>
                              <td className="text-center">
                                <div className="d-flex align-items-center text-center">
                                  <Link
                                    to="/admin"
                                    className="text-bg-info text-white fs-3 rounded-circle round-40 me-n2 card-hover border border-2 border-white d-flex align-items-center justify-content-center"
                                  >
                                    <b>GB</b>
                                  </Link>
                                </div>
                              </td>
                              <td>
                                <button
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  data-bs-original-title="View Enquiry"
                                  type="button"
                                  className="btn mb-1 btn-primary btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                                >
                                  <i className="fs-5 ti ti-eye"></i>
                                </button>
                                <button
                                  type="button"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  data-bs-original-title="Download Invoice"
                                  className="btn mb-1 btn-success btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                                >
                                  <i className="fs-5 ti ti-file-invoice"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td>5343</td>
                            <td>
                              <Link to="/admin/profile">Gaurav Borse</Link>
                            </td>
                            <td>
                              <span className="badge fs-2 rounded-pill text-bg-success">
                                <b>Paid</b>
                              </span>
                            </td>
                            <td>₹ 3450</td>
                            <td>8329007426</td>
                            <td>
                              <span className="badge fs-2 rounded-pill text-bg-primary">
                                <b>New</b>
                              </span>
                            </td>
                            <td>14/03/2024</td>
                            <td>Narhe, Pune</td>
                            <td className="text-center">
                              <div className="d-flex align-items-center text-center">
                                <Link
                                  to="/admin"
                                  className="text-bg-info text-white fs-3 rounded-circle round-40 me-n2 card-hover border border-2 border-white d-flex align-items-center justify-content-center"
                                >
                                  <b>GB</b>
                                </Link>
                              </div>
                            </td>
                            <td>
                              <button
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-bs-original-title="View Enquiry"
                                type="button"
                                className="btn mb-1 btn-primary btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                              >
                                <i className="fs-5 ti ti-eye"></i>
                              </button>
                              <button
                                type="button"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-bs-original-title="Download Invoice"
                                className="btn mb-1 btn-success btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                              >
                                <i className="fs-5 ti ti-file-invoice"></i>
                              </button>
                            </td>
                          </tr>

                          <tr>
                            <td>7567</td>
                            <td>
                              <Link to="/admin/profile">Yash Tarle</Link>
                            </td>
                            <td>
                              <span className="badge fs-2 rounded-pill text-bg-warning">
                                <b>Pending</b>
                              </span>
                            </td>
                            <td>--</td>
                            <td>3445445654</td>
                            <td>
                              <span className="badge fs-2 rounded-pill text-bg-secondary">
                                <b>Revised</b>
                              </span>
                            </td>
                            <td>27/09/2024</td>
                            <td>Narhe, Pune</td>
                            <td className="text-center">
                         
                              <span className="badge fs-2 rounded-pill text-bg-danger">
                                <b>Not Assigned</b>
                              </span>
                            </td>
                            <td>
                              <button
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-bs-original-title="View Enquiry"
                                type="button"
                                className="btn mb-1 btn-primary btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                              >
                                <i className="fs-5 ti ti-eye"></i>
                              </button>
                              <button
                                type="button"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-bs-original-title="Download Invoice"
                                className="btn mb-1 btn-success btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                              >
                                <i className="fs-5 ti ti-file-invoice"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </div>
    </>
  );
};

export default Enquiry;
