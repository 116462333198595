import React from "react";
import { Link, useNavigate } from "react-router-dom";

// Images
import darkLogo from "../assets/images/logos/dark-logo.svg";
import userProfile from "../assets/images/profile/user-1.jpg";
import iconAccount from "../assets/images/svgs/icon-account.svg";
import iconTask from "../assets/images/svgs/warning.svg";
import { useCollapse } from "../assets/Context/GlobalContext";
import NewEnquiry from "./NewEnquiry";

const Header = () => {
  const { isCollapsed, toggleCollapse } = useCollapse();
  // const navigate = useNavigate();

  const userDetails =
    sessionStorage.getItem("info") || sessionStorage.getItem("EmployeeInfo");
  const useInfo = JSON.parse(userDetails);

  const handleLogout = () => {
    if (sessionStorage.getItem("info") !== null) {
      sessionStorage.removeItem("info");
    }
    if (sessionStorage.getItem("EmployeeInfo") !== null) {
      sessionStorage.removeItem("EmployeeInfo");
    }
  };

  return (
    <>
      <header className="app-header">
        <nav className="navbar navbar-expand-lg navbar-light">
          {/* <ul className="navbar-nav">
            <li className="nav-item">
              <button
                className="nav-link sidebartoggler nav-icon-hover ms-n3"
                id="headerCollapse"
                onClick={toggleCollapse}
              >
                <i className="ti ti-menu-2"></i>
              </button>
            </li>
          </ul> */}
          <div className="d-block d-lg-none">
            <img
              src={require("../assets/images/logos/logo.png")}
              className="dark-logo"
              width="100"
              alt=""
            />
          </div>
          <button
            className="navbar-toggler p-0 border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="p-2">
              <i className="ti ti-dots fs-7"></i>
            </span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <div className="d-flex align-items-center justify-content-between">
              {/* <a href="javascript:void(0)" className="nav-link d-flex d-lg-none align-items-center justify-content-center" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobilenavbar" aria-controls="offcanvasWithBothOptions">
                  <i className="ti ti-align-justified fs-7"></i>
                </a> */}
              <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link pe-0"
                    // href="#"
                    id="drop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center">
                      <div className="user-profile-img">
                        <img
                          src={userProfile}
                          className="rounded-circle"
                          width="35"
                          height="35"
                          alt=""
                        />
                      </div>
                    </div>
                  </Link>
                  <div
                    className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
                    aria-labelledby="drop1"
                  >
                    <div
                      className="profile-dropdown position-relative"
                      data-simplebar
                    >
                      <div className="py-3 px-7 pb-0">
                        <h5 className="mb-0 fs-5 fw-semibold">User Profile</h5>
                      </div>
                      <div className="d-flex align-items-center py-9 mx-7 border-bottom">
                        <img
                          src={userProfile}
                          className="rounded-circle"
                          width="80"
                          height="80"
                          alt=""
                        />
                        <div className="ms-3">
                          <h5 className="mb-1 fs-3">
                            <b>
                              {useInfo && (useInfo.name || useInfo.user_name)}
                            </b>
                          </h5>
                          <span className="mb-1 d-block text-dark">
                            {sessionStorage.getItem("info")
                              ? "Admin"
                              : "Employee"}
                          </span>
                          <p className="mb-0 d-flex text-dark align-items-center gap-2">
                            <i className="ti ti-mail fs-4"></i>{" "}
                            {useInfo && useInfo.email}
                          </p>
                        </div>
                      </div>
                      <div className="message-body">
                        <Link
                          to={
                            sessionStorage.getItem("info")
                              ? "/admin/profile"
                              : "/employee/profile"
                          }
                          className="py-8 px-7 mt-8 d-flex align-items-center"
                        >
                          <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <img
                              src={iconAccount}
                              alt=""
                              width="24"
                              height="24"
                            />
                          </span>
                          <div className="w-75 d-inline-block v-middle ps-3">
                            <h6 className="mb-1 bg-hover-primary fw-semibold">
                              My Profile
                            </h6>
                            <span className="d-block text-dark">
                              Account Settings
                            </span>
                          </div>
                        </Link>
                        <Link
                          to={
                            sessionStorage.getItem("info")
                              ? "/login"
                              : "/employee_login"
                          }
                          className="py-8 px-7 mt-0 d-flex align-items-center"
                          onClick={handleLogout}
                        >
                          <span className="d-flex align-items-center justify-content-center bg-light rounded-1 p-6">
                            <img src={iconTask} alt="" width="24" height="24" />
                          </span>
                          <div className="w-75 d-inline-block v-middle ps-3">
                            <Link
                              to={
                                sessionStorage.getItem("info")
                                  ? "/login"
                                  : "/employee_login"
                              }
                              onClick={handleLogout}
                              className="mb-1 bg-hover-primary fw-semibold"
                            >
                              Logout
                            </Link>

                            <span className="d-block text-dark">
                              See you soon again.
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>


          {/* show  pop when new enquiry comming */}
          <NewEnquiry/>
        </nav>
      </header>
    </>
  );
};

export default Header;
