import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

// CSS
import "./App.css"

// Auth:Common
import Login from './pages/common/Login';
import ForgetPass from './pages/common/ForgetPass';
import Error from './pages/common/Error';

// Admin Pages
import Main from './pages/admin/Main';
import Customer from './pages/admin/Customer';
import Employee from './pages/admin/Employee';
import Branch from './pages/admin/Branch';
import Profile from './pages/admin/Profile';
import CustProfile from './pages/admin/CustProfile';
import JobDetail from './pages/admin/JobDetail';
import Enquiry from './pages/admin/Enquiry';
import PaymentAmt from './pages/admin/PaymentAmt';
import Report from './pages/admin/Report';

import { GlobalContextProvider } from './assets/Context/GlobalContext';

// Employee Pages
import EmployeeLogin from './pages/employee/EmployeeLogin';
import EmployeeCustomer from './pages/employee/EmployeeCustomer';
import EmployeeCustomerDetails from './pages/employee/EmployeeCustomerDetails';
import EmployeeMain from './pages/employee/EmployeeMain';
import Employee_Enquiry from './pages/employee/Employee_Enquiry';
import Employee_Customer from './pages/employee/Employee_Customer';
import Employee_Profile from './pages/employee/Employee_Profile';
import OtpForm from './pages/common/OtpForm';
import ResetPassword from './pages/common/ResetPassword';

function App() {

  return (
    <>
      <GlobalContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<Error />} />
            <Route exact path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path="/forget-password/:user_type" element={<ForgetPass />} />
            <Route path="/otp" element={<OtpForm />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route path="/employee_login" element={<EmployeeLogin />} />

            <Route path="/admin" element={<Main />} />
            <Route path="/admin/customers" element={<Customer />} />
            <Route path="/admin/employees" element={<Employee />} />
            <Route path="/admin/branch" element={<Branch />} />
            <Route path="/admin/enquiry" element={<Enquiry />} />
            <Route path="/admin/report" element={<Report />} />
            <Route path="/admin/profile" element={<Profile />} />
            <Route path="/admin/enquiry-charges" element={<PaymentAmt />} />
            <Route path="/admin/customer-profile/:custId" element={<CustProfile />} />
            <Route path="/admin/job-detail/:jobId" element={<JobDetail />} />


            <Route path="/employee" element={<EmployeeMain />} />
            <Route path="/employee/enquiry" element={<Employee_Enquiry />} />
            <Route path="/employee/job-detail/:jobId" element={<Employee_Customer />} />
            <Route path="/employee/profile" element={<Employee_Profile />} />
            {/* <Route path="/employee/employee_customer" element={<EmployeeCustomer />} />
            <Route path="/employee/customer-details" element={<EmployeeCustomerDetails />} /> */}

          </Routes>
        </BrowserRouter>
      </GlobalContextProvider>
    </>
  );
}

export default App
