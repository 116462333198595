import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const EmployeeLogin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(()=>{
    if(sessionStorage.getItem("EmployeeInfo") !== null){
       navigate( "/employee");
    }
  },[])

  const handleInputOnchange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleValidation = () => {
    let valid = true;
    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email === "") {
      toast.error("Email Is Require");
      valid = false;
    } else if (!emailRegex.test(formData.email)) {
      toast.error("Invalid email format");
      valid = false;
    } else if (formData.password === "") {
      toast.error("Password is Require");
      valid = false;
    }
    return valid;
  };

  const handleEmpLogin = async (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }
    const { email, password } = formData;
    try {
      const finalData = {
        user_email: email,
        password: password,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/admin/employee_login",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("employee login response", response.data);
      if (response.data.status === true) {
        sessionStorage.setItem("EmployeeInfo", JSON.stringify(response.data.data));
        sessionStorage.setItem("EmployeeInfo", JSON.stringify({...response.data.data, user_type: "employee"}));
        navigate("/employee");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <>
      <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center w-100">
          <div className="row justify-content-center w-100">
            <div className="col-md-8 col-lg-6 col-xl-4 col-xxl-3">
              <div className="card mb-0">
                <div className="card-body">
                  <Link
                    to="/login"
                    className="text-nowrap logo-img text-center d-block mb-5 w-100"
                  >
                    <h3><b>EMPLOYEE LOGIN</b></h3>
                  </Link>
                  <form onSubmit={handleEmpLogin}>
                    <div className="mb-3">
                      <label htmlFor="exampleInputEmail1" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={handleInputOnchange}
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="exampleInputPassword1" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        onChange={handleInputOnchange}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary w-100 py-8 rounded-2 mb-3"
                    >
                      Sign In
                    </button>
                    <div className="d-flex align-items-center justify-content-end mb-2">
                      <Link
                        className="text-primary fw-medium"
                        to={`/forget-password/${"employee"}`}
                      >
                        Forgot Password ?
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
    </>
  );
};

export default EmployeeLogin;
