import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import notificationSound from "../assets/images/audio/notification.mp3";
import useSound from "use-sound";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../Constant/Global";
import { useNavigate } from "react-router-dom";

const NewEnquiry = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState();
  const [soundPlayed, setSoundPlayed] = useState(false);
  // Set showModal to true when the component mounts
  const navigate = useNavigate();
  const sessionDataString = sessionStorage.getItem("EmployeeInfo");
  const sessionData = JSON.parse(sessionDataString);

  const playNotificationSound = (audioPath) => {
    if (!soundPlayed) {
      const audio = new Audio(audioPath);
      audio.play();
      audio.addEventListener("ended", () => {
        audio.remove(); // Remove the audio element from the DOM after it finishes playing
      });
      setSoundPlayed(true);
    }
  };

  if (sessionStorage.getItem("info")) {
    var handleAddNewLead = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + "api/admin/get_new_enquiry",
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        if (response.data.status) {
          setData(response.data.data);
          setShowModal(true);
          playNotificationSound(notificationSound);
        } else {
          console.log("not found new lead");
        }
      } catch (error) {
        console.log("error occurred fetching data", error);
      }
    };
  } else {
    var handleAddNewLeadEmployee = async () => {
      const finalData = { employee_id: sessionData.id };
      try {
        const response = await axios.post(
          API_BASE_URL + "api/admin/get_new_assigned_enquiry",
          finalData,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        if (response.data.status) {
          setData(response.data.data);
          setShowModal(true);
          playNotificationSound(notificationSound);
        } else {
          console.log("not found new lead");
        }
      } catch (error) {
        console.log("error occurred fetching data", error);
      }
    };
  }

  useEffect(() => {
    // Call handleAddNewLead initially when the component mounts
    if (sessionStorage.getItem("info")) {
      handleAddNewLead();
      // Call handleAddNewLead every five seconds
      const intervalId = setInterval(handleAddNewLead, 5000);

      return () => clearInterval(intervalId);
    } else {
      handleAddNewLeadEmployee();
      // Call handleAddNewLead every five seconds
      const intervalId = setInterval(handleAddNewLeadEmployee, 5000);

      return () => clearInterval(intervalId);
    }
  }, []);

  //   useEffect(() => {

  //     if (data && data.length > 0) {
  //       playNotificationSound(notificationSound);
  //     }
  //   }, [data]);

  //   update lead
  const handleUpdateLeadEmployee = async () => {
    const finalData = { enquiry_id: data.inquiry_id };
    const updateUrl = sessionDataString
      ? "api/admin/update_assigned_enquiry_status"
      : "api/admin/update_new_enquiry_status";
    try {
      const response = await axios.post(API_BASE_URL + updateUrl, finalData, {
        auth: {
          username: API_AUTH_USERNAME,
          password: API_AUTH_PASSWORD,
        },
      });
      if (response.data.status) {
        console.log("update lead", response.data);
      } else {
        console.log("not found new lead");
      }
    } catch (error) {
      console.log("error occurred fetching data", error);
    }
  };
  return (
    <Modal className="mt-5" show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header>
        <Modal.Title>
          {data && data.is_revised === "Y" ? "Revised" : data && data.is_revised === "N" ? "New" : "Decline Design for "} Enquiry
        </Modal.Title>
        <button
          onClick={() => {
            setShowModal(false);
            handleUpdateLeadEmployee();
            // navigate(sessionDataString ? `/employee`: `/admin`)
          }}
          className="btn_close_pop"
        >
          <IoMdClose />
        </button>
        <audio id="notificationSound" src={notificationSound}></audio>
      </Modal.Header>
      <hr className="m-0 p-0" />
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label" htmlFor="tb-pwd">
                Company Name
              </label>
              <input
                type="text"
                name="name"
                defaultValue={data && data.company_name}
                className="form-control"
                readOnly
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label" htmlFor="tb-pwd">
                Customer Name
              </label>
              <input
                type="text"
                name="name"
                defaultValue={data && data.customer_name}
                className="form-control"
                readOnly
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label" htmlFor="tb-pwd">
                Address
              </label>
              <input
                type="text"
                name="name"
                defaultValue={data && data.address}
                className="form-control"
                readOnly
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label" htmlFor="tb-pwd">
                Site Name
              </label>
              <input
                type="text"
                name="name"
                defaultValue={data && data.site_name}
                className="form-control"
                readOnly
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label" htmlFor="tb-pwd">
                Site Address
              </label>
              <input
                type="text"
                name="name"
                defaultValue={data && data.site_address}
                className="form-control"
                readOnly
              />
            </div>
          </div>
          {data && data.design_note ? (
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label" htmlFor="tb-pwd">
                  Design Rejection Note :
                </label>
                <input
                  type="text"
                  name="name"
                  defaultValue={data && data.design_note}
                  className="form-control"
                  readOnly
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={() => {
            setShowModal(false);
            handleUpdateLeadEmployee();
            navigate(
              sessionDataString
                ? `/employee/job-detail/${data.inquiry_id}`
                : `/admin/job-detail/${data.inquiry_id}`
            );
          }}
        >
          View Lead
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewEnquiry;
