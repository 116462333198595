import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";

// CSS
import "./customer_profile.css";
import Pagination from "./Pagination";

const CustProfile = () => {
  const [custData, setCustData] = useState({
    name: "",
    email: "",
    mobile: "",
    area: "",
  });
  const [custEqData, setCustEqData] = useState([]);
  const { custId } = useParams();


   // page navigation section

   const pageSize = 10; // Number of items per page
   const [currentPage, setCurrentPage] = useState(1);
   const [pageCount, setPageCount] = useState(1);
   const [totalCount, setTotalCount] = useState(0);
   
 
  // pagination onchange
  const onPageChange = (page) => {
   setCurrentPage(page);
   window.scrollTo(0, 0); // Scroll to the top of the page
 };
 
  


  const handleOnchange = (e) => {
    setCustData({ ...custData, [e.target.name]: e.target.value });
    console.log(custData);
  };

  // customer details by id
  const fetchCustomerDetails = async () => {
    const finalData = { customer_id: custId };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/customer_details",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("Customer details response", response.data);
      const data = response.data.data;
      setCustData(data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const handleRefresh = async () => {
    await fetchCustomerDetails();
  };

  // fetch customer enquiries
  const fetchCustomerEnq = async () => {
    const finalData = { customer_id: custId, page: currentPage, page_limit: pageSize };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/customer_enquiries_list",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("Customer Enquiries details response", response.data);
      const data = response.data.data;
      setCustEqData(data);
      setPageCount(response.data.other_data.np_of_pages);
      setTotalCount(response.data.other_data.customer_count);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchCustomerDetails();
    fetchCustomerEnq();
  }, [custId,currentPage]);

  // Total Invested Amount
  // const totalInvestedAmount = custEqData.reduce((total, inquiry) => {
  //   return total + parseFloat(inquiry.amount);
  // }, 0);

  const totalInvestedAmount = custEqData.reduce((accumulator, currentItem) => {
    // Check if currentItem.amount and currentItem.revised_amount exist and are valid numbers
    const amount = parseInt(currentItem.amount || 0) || 0;
    const revisedAmount = parseInt(currentItem.revised_amount || 0) || 0;

    // Add current item's amount and revised_amount to the accumulator
    return accumulator + amount + revisedAmount;
  }, 0);

  // Filter inquiries with status 'Done' & 'Pending'
  const doneInquiries = custEqData.filter(
    (inquiry) => inquiry.status === "Done"
  );
  const pendingInquiries = custEqData.filter(
    (inquiry) => inquiry.status === "Pending"
  );

  // Count of inquiries with status 'Done' & 'Pending'
  const doneInquiriesCount = doneInquiries.length;
  const pendingInquiriesCount = pendingInquiries.length;

  const formattedDate = (date) => {
    const myDate = new Date(date);
    return `${String(myDate.getDate()).padStart(2, "0")}-${String(
      myDate.getMonth() + 1
    ).padStart(2, "0")}-${myDate.getFullYear()}`;
  };

  function getInitials(name) {
  if (!name || typeof name !== 'string') {
    return '';
  }

  const parts = name.trim().split(" ");
  const initials = parts.map((part) => part.charAt(0)).join("");
  return initials.toUpperCase();
}


  function getRandomColorClass() {
    const colors = [
      "bg-info",
      "bg-success",
      "bg-secondary",
      "bg-warning",
      "bg-primary",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }

  // validation
  const handleValidation = () => {
    let valid = true;
    // Email format validation
    const regex = /^[A-Za-z ]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (custData.name === "") {
      toast.error("Name is required!");
      valid = false;
    } else if (!regex.test(custData.name)) {
      toast.error("Name is invalid");
      valid = false;
    } else if (custData.email === "") {
      toast.error("Email is required!");
      valid = false;
    } else if (!emailRegex.test(custData.email)) {
      toast.error("Invalid email format");
      valid = false;
    } else if (custData.mobile === "") {
      toast.error("Mobile number is required!");
      valid = false;
    } else if (custData.mobile.length !== 10) {
      toast.error("Mobile number must be at least 10 digits long.");
      valid = false;
    } else if (custData.area === "") {
      toast.error("Area is required!");
      valid = false;
    }
    return valid;
  };

  // update customer
  const handleUpdateCustomer = async (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }
    try {
      const { name, email, mobile, area } = custData;

      const finalData = {
        customer_id: custData.id,
        name: name,
        email: email,
        mobile: mobile,
        area: area,
      };
      console.log("update customer finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/admin/edit_customer",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        console.log("update customer response", response.data);
        toast.success(response.data.message);
        // window.location.reload();
        handleRefresh();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="body-wrapper">
        <Header />
        {/* Start from here */}
        <div className="container-fluid">
          <div className="card bg-light-info shadow-none position-relative overflow-hidden">
            <div className="card-body px-4 py-3">
              <div className="row align-items-center">
                <div className="col-12 col-md-8 col-lg-9 border-bottom-lg">
                  <div className="row">
                    <div className="d-flex align-items-center gap-2 mb-2 bg-light-info p-2 rounded">
                      <div className="position-relative">
                        <i
                          className="ti ti-user text-primary"
                          style={{ fontSize: "43px" }}
                        ></i>
                      </div>
                      <div>
                        <h3 className="fw-semibold mb-0">
                          <span className="text-dark">{custData.name}</span>
                        </h3>
                        <span>
                          Customer Since: {formattedDate(custData.created_at)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link className="text-muted" to="/admin">
                          Dashboard
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link className="text-muted" to="/admin/customers">
                          Customers
                        </Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Profile
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="card overflow-hidden">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-primary shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-file-description text-primary"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-primary mt-1 mb-1">
                            Total Enquiries
                            <hr className="mt-2 mb-2 text-primary" />
                          </p>
                          <h4 className="fw-semibold text-primary mb-0">
                            {custEqData.length}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-warning shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-coin-rupee text-warning"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-warning mt-1 mb-1">
                            Invested Amount
                            <hr className="mt-2 mb-2 text-warning" />
                          </p>
                          <h4 className="fw-semibold text-warning mb-0">
                            {totalInvestedAmount}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-info shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-file-description text-info"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-info mt-1 mb-1">
                            Served Enquiries
                            <hr className="mt-2 mb-2 text-info" />
                          </p>
                          <h4 className="fw-semibold text-info mb-0">
                            {doneInquiriesCount}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                  <div className="item">
                    <div className="card border-0 zoom-in bg-light-danger shadow-none">
                      <div className="card-body">
                        <div className="text-center">
                          <i
                            className="ti ti-file-description text-danger"
                            style={{ fontSize: "40px" }}
                          ></i>
                          <p className="fw-semibold fs-5 text-danger mt-1 mb-1">
                            Pending Enquiries
                            <hr className="mt-2 mb-2 text-danger" />
                          </p>
                          <h4 className="fw-semibold text-danger mb-0">
                            {pendingInquiriesCount}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul
                className="nav nav-pills user-profile-tab justify-content-end mt-2 bg-light-info rounded-2"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link position-relative rounded-0 active d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                    id="pills-job-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-job"
                    type="button"
                    role="tab"
                    aria-controls="pills-job"
                    aria-selected="true"
                  >
                    <i className="ti ti-briefcase me-2 fs-6"></i>
                    <span className="d-none d-md-block">
                      Total Enquiries ({totalCount})
                    </span>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-6"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    <i className="ti ti-user-circle me-2 fs-6"></i>
                    <span className="d-none d-md-block">Profile Details</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-job"
              role="tabpanel"
              aria-labelledby="pills-job-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="card shadow-none border">
                    <div className="card-body">
                      <h4 className="fw-semibold mb-3">All Enquiries</h4>
                      <hr />
                      <div className="table-responsive">
                        <table
                          id="custEnquiry"
                          className="table border table-striped table-bordered text-nowrap m-t-30 table-hover"
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Company Name</th>
                              <th>User Name</th>
                              {/* <th>Payment</th> */}
                              {/* <th>Amount Paid</th> */}
                              <th>Enquiry Type</th>
                              <th>Enquiry Date</th>
                              {/* <th>Site Address</th> */}
                              <th>Assigned</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {custEqData.length > 0 ? (
                              custEqData.map((item, index) => {
                                const currentIndex = (currentPage - 1) * 10 + index + 1;
                                return (
                                  <tr
                                    key={index}
                                    className={getRandomColorClass()}
                                  >
                                    <td>
                                      <Link to={`/admin/job-detail/${item.id}`}>
                                        {currentIndex}
                                      </Link>
                                    </td>
                                    <td>
                                      {" "}
                                      {item.company_name.length > 15
                                        ? `${item.company_name.slice(0, 30)}...`
                                        : item.company_name}
                                    </td>
                                    <td>
                                      <Link
                                        to={`/admin/customer-profile/${item.user_id}`}
                                      >
                                        {item.customer_name}
                                      </Link>
                                    </td>

                                    {/* <td>
                                  {item.payment_id === null ? (
                                    <span className="badge fs-2 rounded-pill text-bg-warning">
                                      <b>Pending</b>
                                    </span>
                                  ) : (
                                    <span className="badge fs-2 rounded-pill text-bg-success">
                                      <b>Paid</b>
                                    </span>
                                  )}
                                </td> */}
                                    {/* <td>{item.amount}</td> */}
                                    <td>
                                      {item.is_revised === "N" ? (
                                        <span className="badge fs-2 text-bg-primary badge-width">
                                          <b>New</b>
                                        </span>
                                      ) : (
                                        <span className="badge fs-2 text-bg-secondary badge-width">
                                          <b>Revised</b>
                                        </span>
                                      )}
                                    </td>
                                    <td>{formattedDate(item.created_at)}</td>
                                    {/* <td>{item.site_address}</td> */}
                                    <td>
                                      <div className="d-flex">
                                        {item.assigned_to === null ? (
                                          <span
                                            className="badge fs-2 text-bg-danger card-hover rounded-circle round-40 d-flex align-items-center justify-content-center"
                                            title="Not Assigned"
                                          >
                                            <b>NO</b>
                                          </span>
                                        ) : (
                                          <button
                                            title={item.employee_name}
                                            className={`text-white fs-3 rounded-circle round-40 me-n2 card-hover border border-2 d-flex align-items-center justify-content-center ${getRandomColorClass()}`}
                                          >
                                            <b>
                                              {getInitials(item.employee_name)}
                                            </b>
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      {(() => {
                                        if (item.status === "Pending") {
                                          return (
                                            <span className="badge bg-warning badge-width-status">
                                              Pending
                                            </span>
                                          );
                                        } else if (item.status === "Done") {
                                          return (
                                            <span className="badge  bg-success badge-width-status">
                                              Done
                                            </span>
                                          );
                                        } else if (
                                          item.status === "In-progress"
                                        ) {
                                          return (
                                            <span className="badge  bg-info badge-width-status">
                                              In-progress
                                            </span>
                                          );
                                        } else if (
                                          item.status === "Under Design"
                                        ) {
                                          return (
                                            <span className="badge  bg-primary badge-width-status">
                                              Under Design
                                            </span>
                                          );
                                        } else if (
                                          item.status === "Under Revision"
                                        ) {
                                          return (
                                            <span className="badge  bg-secondary badge-width-status">
                                              Under Revision
                                            </span>
                                          );
                                        }
                                      })()}
                                    </td>
                                    <td>
                                      <Link
                                        to={`/admin/job-detail/${item.id}`}
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-original-title="View Enquiry"
                                        // type="button"
                                        className="btn mb-1 btn-primary btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                                      >
                                        <i className="fs-5 ti ti-eye"></i>
                                      </Link>
                                      {/* <button
                                        type="button"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-original-title="Download Invoice"
                                        className="btn mb-1 btn-success btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                                      >
                                        <i className="fs-5 ti ti-file-invoice"></i>
                                      </button> */}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr className="text-center">
                                <td colSpan={11}>No data or Loading Data...</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {custEqData.length > 0 && (
                          <div>
                            <Pagination
                              currentPage={currentPage}
                              totalPages={pageCount}
                              onPageChange={onPageChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade show"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="card shadow-none border">
                    <div className="card-body">
                      <h4 className="fw-semibold mb-3">Profile Details</h4>
                      <hr />
                      <form
                        className="form-horizontal form-material mt-4"
                        onSubmit={handleUpdateCustomer}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                value={custData.name}
                                onChange={handleOnchange}
                                className="form-control"
                                placeholder="Enter Name here"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Email Address
                              </label>
                              <input
                                type="text"
                                name="email"
                                value={custData.email}
                                onChange={handleOnchange}
                                className="form-control"
                                placeholder="Enter email here"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="form-label" for="tb-pwd">
                              Mobile No.
                            </label>
                            <div className="input-group mb-3">
                              <span
                                className="input-group-text contactGroup"
                                id="basic-addon1"
                              >
                                +91
                              </span>
                              <input
                                type="tel"
                                name="mobile"
                                value={custData.mobile}
                                onChange={handleOnchange}
                                className="form-control"
                                placeholder="Enter mobile here"
                                aria-describedby="basic-addon1"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Area
                              </label>
                              <input
                                type="text"
                                name="area"
                                value={custData.area}
                                onChange={handleOnchange}
                                className="form-control"
                                placeholder="Enter email here"
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-success waves-effect"
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </>
  );
};

export default CustProfile;
