/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import axios from "axios";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";

const PaymentAmt = () => {
  const [enqCharges, setEnqCharges] = useState({
    inquiry_charge: "",
    inquiry_revised_charge: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("info") == null) {
      navigate("/");
    }
  }, [navigate]);
  const handleOnchange = (e) => {
    setEnqCharges({ ...enqCharges, [e.target.name]: e.target.value });
  };

  // customer data
  const fetchEnquiryCharges = async () => {
    try {
      const response = await axios.get(
        API_BASE_URL + "api/admin/get_enquiry_charge",
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("Enquiry Charges data response", response.data);
      setEnqCharges(response.data.data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchEnquiryCharges();
  }, []);

  // when change details
  const handleRefresh = async () => {
    await fetchEnquiryCharges();
  };

  const handleValidation = () => {
    let valid = true;

    // Regular expression to match numbers
    const numberRegex = /^[0-9]+$/;

    if (
      enqCharges.inquiry_charge_same_side === "" ||
      !numberRegex.test(enqCharges.inquiry_charge_same_side)
    ) {
      toast.error("Valid Enquiry Charge is required!");
      valid = false;
    } else if (
      enqCharges.inquiry_charge_opposite_side === "" ||
      !numberRegex.test(enqCharges.inquiry_charge_opposite_side)
    ) {
      toast.error("Valid Opposite Side Charge is required!");
      valid = false;
    } else if (
      enqCharges.inquiry_charge_adjustment_side === "" ||
      !numberRegex.test(enqCharges.inquiry_charge_adjustment_side)
    ) {
      toast.error("Valid Adjustment Side Charge is required!");
      valid = false;
    } else if (
      enqCharges.inquiry_charge_kg === "" ||
      !numberRegex.test(enqCharges.inquiry_charge_kg)
    ) {
      toast.error("Valid Capacity in Kg Charge is required!");
      valid = false;
    } else if (
      enqCharges.inquiry_revised_charge === "" ||
      !numberRegex.test(enqCharges.inquiry_revised_charge)
    ) {
      toast.error("Valid Revised Enquiry Charge is required!");
      valid = false;
    }

    return valid;
  };

  // update enquiry charges
  const handleUpdateEnqCharges = async (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }
    try {
      const finalData = {
        inquiry_charge_same_side: enqCharges.inquiry_charge_same_side,
        inquiry_charge_opposite_side: enqCharges.inquiry_charge_opposite_side,
        inquiry_charge_adjustment_side:
          enqCharges.inquiry_charge_adjustment_side,
        inquiry_charge_kg: enqCharges.inquiry_charge_kg,
        inquiry_revised_charge: enqCharges.inquiry_revised_charge,
      };
      console.log("update enquiry charges finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/admin/update_enquiry_charge",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        console.log("update enquiry charges  response", response.data);
        toast.success(response.data.message);
        //   window.location.reload();
        handleRefresh();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="body-wrapper">
        <Header />
        {/* Start from here */}
        <div className="container-fluid">
          <div className="card bg-light-info shadow-none position-relative overflow-hidden">
            <div className="card-body px-4 py-3">
              <div className="row align-items-center">
                <div className="col-12 col-md-8 col-lg-9 border-bottom-lg">
                  <h4 className="fw-semibold mb-0">Enquiry Charges</h4>
                  <p className="mb-0">
                    You can manage enquiry charges from here..
                  </p>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link className="text-muted " to="/admin">
                          Dashboard
                        </Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Enquiry Charges
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card   border border-2 shadow-none">
                <div className="card-body">
                  <h4 className="fw-semibold mb-3">Change Enquiry Charges</h4>
                  <hr />
                  <form
                    onSubmit={handleUpdateEnqCharges}
                    className="form-horizontal form-material mt-4"
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" for="tb-pwd">
                            Same Side Opening
                          </label>
                          <input
                            type="text"
                            name="inquiry_charge_same_side"
                            value={enqCharges.inquiry_charge_same_side}
                            onChange={handleOnchange}
                            className="form-control"
                            placeholder="Enter same side opening"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" for="tb-pwd">
                            Opposite Side Opening
                          </label>
                          <input
                            type="text"
                            name="inquiry_charge_opposite_side"
                            value={enqCharges.inquiry_charge_opposite_side}
                            onChange={handleOnchange}
                            className="form-control"
                            placeholder="Enter opposite side opening"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" for="tb-pwd">
                            Adjacent Side Opening
                          </label>
                          <input
                            type="text"
                            name="inquiry_charge_adjustment_side"
                            value={enqCharges.inquiry_charge_adjustment_side}
                            onChange={handleOnchange}
                            className="form-control"
                            placeholder="Enter adjacent side opening"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" for="tb-pwd">
                            Capacity in Kg(1.5 tone or above)
                          </label>
                          <input
                            type="text"
                            name="inquiry_charge_kg"
                            value={enqCharges.inquiry_charge_kg}
                            onChange={handleOnchange}
                            className="form-control"
                            placeholder="Enter capacity in kg "
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" for="tb-pwd">
                            Revised Enquiry Charge
                          </label>
                          <input
                            type="text"
                            name="inquiry_revised_charge"
                            value={enqCharges.inquiry_revised_charge}
                            onChange={handleOnchange}
                            className="form-control"
                            placeholder="Enter revised enquiry charge "
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-success waves-effect"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </>
  );
};

export default PaymentAmt;
