import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import axios from "axios";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "./Pagination";

const Employee = () => {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("N");
  const [id, setId] = useState(null);
  const [close, setClose] = useState(true);
  const [employeeData, setEmployeeData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
    status: "",
    employee_type:"normal"
  });

  const navigate = useNavigate();

  // page navigation section

  const pageSize = 10; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  

 // pagination onchange
 const onPageChange = (page) => {
  setCurrentPage(page);
  window.scrollTo(0, 0); // Scroll to the top of the page
};

  useEffect(() => {
    if (sessionStorage.getItem("info") == null) {
      navigate("/");
    }
  }, [navigate]);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setEmployeeData({
      ...employeeData,
      status: isChecked ? "Y" : "N",
    });
  };

  useEffect(() => {
    console.log("status", status);
  }, [status]);

  const handleOnchange = (e) => {
    setEmployeeData({ ...employeeData, [e.target.name]: e.target.value });
  };

  // employee data
  const handleEmployeeData = async () => {
    const finalData = { page: currentPage, page_limit: pageSize };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/employee_list",finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("employee data response", response.data);
      setData(response.data.data);
      setPageCount(response.data.other_data.np_of_pages);
      setTotalCount(response.data.other_data.employee_count);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    handleEmployeeData();
  }, [currentPage]);

  // when change details
  const handleRefresh = async () => {
    await handleEmployeeData();
  };

  const handleAddEmployee = async (e) => {
    e.preventDefault(e);
    const { name, email, mobile, password, confirmPassword, employee_type } = employeeData;
    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (name === "") {
      toast.error("Name is required!");
      return;
    } else if (email === "") {
      toast.error("Email is required!");
      return;
    } else if (!emailRegex.test(email)) {
      toast.error("Invalid email format");
      return;
    } else if (mobile === "") {
      toast.error("Mobile number is required!");
      return;
    } else if (!password.trim() || !confirmPassword.trim()) {
      toast.error("Password and Confirm Password cannot be blank.");
      return;
    } else if (password.length < 6 && confirmPassword.length < 6) {
      toast.error(
        "Password and Confirm Password must be at least 6 characters long."
      );
      return;
    } else if (password !== confirmPassword) {
      toast.error("Passwords and Confirm Password do not match.");
      return;
    }
    try {
      const finalData = {
        name: name,
        email: email,
        mobile: mobile,
        password: password,
        status: status,
        employee_type:employee_type
      };
      console.log("add employee finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/admin/add_employee",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("add employee response", response.data);
      setClose(false);
      window.location.reload();
      handleRefresh();
      toast.success(response.data.message);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // employee details by id
  const handleEmployeeDetails = async (id) => {
    const finalData = { employee_id: id };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/employee_details",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("employee details response", response.data);
      setEmployeeData({
        ...employeeData,
        name: response.data.data.name,
        email: response.data.data.email,
        mobile: response.data.data.mobile,
        status: response.data.data.status,
        password: response.data.data.password,
        confirmPassword: response.data.data.password,
        employee_type: response.data.data.employee_type
      });
      setStatus(response.data.data.status);
      setId(response.data.data.id);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // validation
  const handleValidation = () => {
    let valid = true;
    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (employeeData.name === "") {
      toast.error("Name is required!");
      valid = false;
    } else if (employeeData.email === "") {
      toast.error("Email is required!");
      valid = false;
    } else if (!emailRegex.test(employeeData.email)) {
      toast.error("Invalid email format");
      valid = false;
    } else if (employeeData.mobile === "") {
      toast.error("Mobile number is required!");
      valid = false;
    } else if (employeeData.mobile.length !== 10) {
      toast.error("Mobile number must be at least 10 digits long.");
      valid = false;
    }else if (employeeData.password !== "" && employeeData.confirmPassword === "") {
      toast.error("Confirm Password is required if Password is entered.");
      valid = false;
    } else if (employeeData.password === "" && employeeData.confirmPassword !== "") {
      toast.error("Password is required if Confirm Password is entered.");
      valid = false;
    } else if (employeeData.password !== "" && employeeData.confirmPassword !== "" && employeeData.password !== employeeData.confirmPassword) {
      toast.error("Passwords and Confirm Password do not match.");
      valid = false;
    } else if (employeeData.password !== "" && employeeData.confirmPassword !== "" && (employeeData.password.length < 6 || employeeData.confirmPassword.length < 6)) {
      toast.error("Password and Confirm Password must be at least 6 characters long.");
      valid = false;
    }
    return valid;
  };

  // update employee
  const handleUpdateEmployee = async (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }
    try {
      const { name, email, mobile, password, status,employee_type } = employeeData;

      const finalData = {
        ...employeeData,
        employee_id: id,
        name: name,
        email: email,
        mobile: mobile,
        password: password,
        status: status === "Y" ? "Y" : "N",
        employee_type:employee_type
      };

      // finalData.status = status === "Y" ? "Y" : "N";
      console.log("Update employee finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/admin/edit_employee",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        toast.success(response.data.message);
        console.log("update employee response", response.data);
        setClose(false);
        handleRefresh();
        // window.location.reload();
        setTimeout(() => {
          window.location.reload();
          const modalBackdrop = document.querySelector(".modal-backdrop");
          if (modalBackdrop) {
            modalBackdrop.classList.remove("show");
          }
        }, 1200); // Adjust the delay as needed
      } else {
        setClose(true);
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  console.log("close response", close);

  // Employee deletion
  const handleEmployeeDel = async (id) => {
    const finalData = { employee_id: id };
    try {
      const response = await axios.post(
        API_BASE_URL + "api/admin/delete_employee",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("employee delete response", response.data);
      toast.success("Employee deleted.");
      handleRefresh();
    } catch (error) {
      console.log(error.response.data.message);
      toast.error("Error while deleting the employee.");
    }
  };

  return (
    <>
      <Sidebar />
      <div className="body-wrapper">
        <Header />
        {/* Start from here */}
        <div className="container-fluid">
          <div className="card bg-light-info shadow-none position-relative overflow-hidden">
            <div className="card-body px-4 py-3">
              <div className="row align-items-center">
                <div className="col-12 col-md-8 col-lg-9 border-bottom-lg">
                  <h4 className="fw-semibold mb-0 d-flex align-items-center gap-2 ">
                    Employee Data{" "}
                    <span className="badge bg-secondary square-pill">
                      {totalCount}
                    </span>{" "}
                  </h4>
                  <p className="mb-0">You can manage employees from here..</p>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link className="text-muted" to="/admin">
                          Dashboard
                        </Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Employees
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-info btn-rounded m-t-10 mb-2"
                      data-bs-toggle="modal"
                      data-bs-target="#add-employee"
                    >
                      <i className="fs-3 ti ti-plus"></i> Add Employee
                    </button>
                    {/* <Link
                      className="btn btn-success btn-rounded m-t-10 mb-2"
                      to=""
                    >
                      <i className="fs-3 ti ti-file-spreadsheet"></i> Export
                    </Link> */}
                  </div>
                  {/* Add Enployee Model */}
                  {close && (
                    <div
                      id="add-employee"
                      className="modal fade"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable modal-lg">
                        <div className="modal-content p-3">
                          <div className="modal-header d-flex align-items-center border-bottom">
                            <h4 className="modal-title" id="myModalLabel">
                              Add New Employee
                            </h4>
                            <button
                              type="button"
                              className="btn-close text-white"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <form className="form-horizontal form-material">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="tb-pwd"
                                    >
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      name="name"
                                      value={employeeData.name}
                                      onChange={handleOnchange}
                                      className="form-control"
                                      placeholder="Enter Name here"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="tb-pwd"
                                    >
                                      Email Address
                                    </label>
                                    <input
                                      type="email"
                                      name="email"
                                      value={employeeData.email}
                                      onChange={handleOnchange}
                                      className="form-control"
                                      placeholder="Enter email here"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <label
                                    className="form-label"
                                    htmlFor="tb-pwd"
                                  >
                                    Mobile No.
                                  </label>
                                  <div className="input-group mb-3">
                                    <span
                                      className="input-group-text contactGroup"
                                      id="basic-addon1"
                                    >
                                      +91
                                    </span>
                                    <input
                                      type="tel"
                                      name="mobile"
                                      value={employeeData.mobile}
                                      onChange={handleOnchange}
                                      className="form-control"
                                      placeholder="Enter mobile here"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Employee Type
                                    </label>
                                    <div className="employee_type">
                                      <label htmlFor="">
                                        <input
                                          className=""
                                          type="radio"
                                          name="employee_type"
                                          value="normal"
                                          checked={
                                            employeeData.employee_type ===
                                            "normal"
                                          }
                                          onChange={handleOnchange}
                                        />
                                       {" "} {" "} Normal
                                      </label>
                                      <label htmlFor="">
                                        <input
                                          className=""
                                          type="radio"
                                          name="employee_type"
                                          value="freelancer"
                                          checked={
                                            employeeData.employee_type ===
                                            "freelancer"
                                          }
                                          onChange={handleOnchange}
                                        />
                                       {" "} {" "} Freelancer
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Password
                                    </label>
                                    <input
                                      className="form-control"
                                      type="password"
                                      name="password"
                                      value={employeeData.password}
                                      onChange={handleOnchange}
                                      placeholder="Enter password here"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Confirm Password
                                    </label>
                                    <input
                                      className="form-control"
                                      type="password"
                                      name="confirmPassword"
                                      value={employeeData.confirmPassword}
                                      onChange={handleOnchange}
                                      placeholder="Enter confirm password here"
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-md-6">
                                  <div className="form-check mt-1">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="status"
                                      value=""
                                      id="flexCheckChecked"
                                      // checked={status}
                                      onChange={handleCheckboxChange}
                                      // checked
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckChecked"
                                    >
                                      Keep this employee account active.?
                                    </label>
                                    <input
                                      type="hidden"
                                      name="status"
                                      value={status}
                                    />
                                  </div>
                                </div> */}
                                <div className="col-md-6">
                                  <div className="form-check mt-1">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="status"
                                      value=""
                                      id="flexCheckChecked"
                                      onChange={handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckChecked"
                                    >
                                      Keep this employee account active.?
                                    </label>
                                    <input
                                      type="hidden"
                                      name="status"
                                      value={status}
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="submit"
                              onClick={handleAddEmployee}
                              className="btn btn-success waves-effect"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger waves-effect"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Edit Enployee Model */}
                  {close && (
                    <div
                      id="edit-employee"
                      className="modal fade in"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable modal-lg">
                        <div className="modal-content p-3">
                          <div className="modal-header d-flex align-items-center border-bottom">
                            <h4 className="modal-title" id="myModalLabel">
                              Edit Employee Details
                            </h4>
                            <button
                              type="button"
                              className="btn-close text-white"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <form
                              className="form-horizontal form-material"
                              onSubmit={handleUpdateEmployee}
                            >
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="tb-pwd"
                                    >
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      name="name"
                                      className="form-control"
                                      value={employeeData.name}
                                      onChange={handleOnchange}
                                      placeholder="Enter Name here"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      htmlFor="tb-pwd"
                                    >
                                      Email Address
                                    </label>
                                    <input
                                      type="email"
                                      name="email"
                                      className="form-control"
                                      value={employeeData.email}
                                      onChange={handleOnchange}
                                      placeholder="Enter email here"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <label
                                    className="form-label"
                                    htmlFor="tb-pwd"
                                  >
                                    Mobile No.
                                  </label>
                                  <div className="input-group mb-3">
                                    <span
                                      className="input-group-text contactGroup"
                                      id="basic-addon1"
                                    >
                                      +91
                                    </span>
                                    <input
                                      type="tel"
                                      name="mobile"
                                      value={employeeData.mobile}
                                      onChange={handleOnchange}
                                      className="form-control"
                                      placeholder="Enter mobile here"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Employee Type
                                    </label>
                                    <div className="employee_type">
                                      <label htmlFor="">
                                        <input
                                          className=""
                                          type="radio"
                                          name="employee_type"
                                          value="normal"
                                          checked={
                                            employeeData.employee_type ===
                                            "normal"
                                          }
                                          onChange={handleOnchange}
                                        />
                                       {" "} {" "} Normal
                                      </label>
                                      <label htmlFor="">
                                        <input
                                          className=""
                                          type="radio"
                                          name="employee_type"
                                          value="freelancer"
                                          checked={
                                            employeeData.employee_type ===
                                            "freelancer"
                                          }
                                          onChange={handleOnchange}
                                        />
                                       {" "} {" "} Freelancer
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Password (Optional)
                                    </label>
                                    <input
                                      className="form-control"
                                      name="password"
                                      type="password"
                                      value={employeeData.password}
                                      onChange={handleOnchange}
                                      placeholder="Enter password here"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Confirm Password
                                    </label>
                                    <input
                                      className="form-control"
                                      name="confirmPassword"
                                      type="password"
                                      value={employeeData.confirmPassword}
                                      onChange={handleOnchange}
                                      placeholder="Enter confirm password here"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-check mt-1">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="status"
                                      value=""
                                      id="flexCheckChecked"
                                      checked={employeeData.status === "Y"}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckChecked"
                                    >
                                      Keep this employee account active.?
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="submit"
                                  className="btn btn-success waves-effect"
                                >
                                  Submit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger waves-effect"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Edit employee modal */}
                  {/* Table section */}
                  <div className="table-responsive">
                    <table
                      id="demo-foo-addrow"
                      className="table border table-striped table-bordered text-nowrap m-t-30 table-hover"
                      data-paging="true"
                      data-paging-size="7"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Mobile No.</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          data.map((emp, index) => {
                            const currentIndex =
                              (currentPage - 1) * 10 + index + 1;
                            return (
                              <tr>
                                <td>{currentIndex}</td>
                                <td>
                                  <Link to="" className="link">
                                    {emp.name}
                                  </Link>
                                </td>
                                <td>
                                  <a href={`mailto:${emp.email}`}>
                                    {emp.email}
                                  </a>
                                </td>
                                <td>
                                  <a href={`tel:91${emp.mobile}`}>
                                    +91 {emp.mobile}
                                  </a>
                                </td>
                                <td>
                                  {emp.status === "Y" ? (
                                    <span className="badge bg-success">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge bg-danger">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleEmployeeDetails(emp.id)
                                    }
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit-employee"
                                    className="btn mb-1 btn-warning btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                                  >
                                    <i className="fs-5 ti ti-edit"></i>
                                  </button>

                                  <button
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          "Are you sure you want to delete this employee?"
                                        )
                                      ) {
                                        handleEmployeeDel(emp.id);
                                      }
                                    }}
                                    className="btn mb-1 btn-danger btn-circle btn-sm d-inline-flex align-items-center justify-content-center"
                                  >
                                    <i className="fs-5 ti ti-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="text-center">
                            <td colSpan={6}>No data or Loading Data...</td>
                          </tr>
                        )}
                        {/* <tr>
                          <td>2</td>
                          <td>
                            <a href="javascript:void(0)" className="link">
                              Kunal Jadhav
                            </a>
                          </td>
                          <td>kunal@gmail.com</td>
                          <td>
                            <a href="tel:918329007426">+91 83290 07426</a>
                          </td>
                          <td>
                            <span className="badge bg-danger">Inactive</span>
                          </td>
                          <td>
                            <button
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#edit-employee"
                              className="btn mb-1 btn-warning btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                            >
                              <i className="fs-5 ti ti-edit"></i>
                            </button>

                            <a
                              href="./delete"
                              className="btn mb-1 btn-danger btn-circle btn-sm d-inline-flex align-items-center justify-content-center"
                            >
                              <i className="fs-5 ti ti-trash"></i>
                            </a>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                    {data.length > 0 && (
                      <div>
                        <Pagination
                          currentPage={currentPage}
                          totalPages={pageCount}
                          onPageChange={onPageChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />
      </div>
    </>
  );
};

export default Employee;
