import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";

// Images
import userBg from "../../assets/images/backgrounds/profilebg.png";
import userProfile from "../../assets/images/profile/user-1.jpg";
import { Link, useNavigate } from "react-router-dom";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Employee_Profile = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  useEffect(() => {
    const employeeInfo = sessionStorage.getItem("EmployeeInfo");

    // Check if both "info" and "EmployeeInfo" are null
    if (employeeInfo == null) {
      navigate("/employee_login");
      window.location.reload();
    }
  }, [navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleInputOnchange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleValidation = () => {
    let valid = true;

    if (formData.current_password === "") {
      toast.error("Current Password is required");
      valid = false;
    } else if (formData.new_password === "") {
      toast.error("New Password is required");
      valid = false;
    } else if (formData.new_password.length < 6) {
      // assuming a minimum password length of 6 characters
      toast.error("New Password must be at least 6 characters long");
      valid = false;
    } else if (formData.confirm_password === "") {
      toast.error("Confirm Password is required");
      valid = false;
    } else if (formData.new_password !== formData.confirm_password) {
      toast.error("New Password and Confirm Password do not match");
      valid = false;
    }

    return valid;
  };

  // Retrieve data from sessionStorage
  const sessionDataString = sessionStorage.getItem("EmployeeInfo");
  const sessionData = JSON.parse(sessionDataString);

  useEffect(() => {
    setProfileData({
      name: sessionData.name,
      email: sessionData.email,
      mobile: sessionData.mobile,
    });
  }, []);

  // change password
  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (!handleValidation()) {
      return;
    }
    const { current_password, new_password } = formData;
    try {
      const finalData = {
        user_id: sessionData.id,
        current_password: current_password,
        new_password: new_password,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/admin/change_password_employee",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("change password response", response.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/employee");
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const handleValidationProfile = () => {
    let valid = true;
    const regex = /^[A-Za-z ]+$/;
    const emaRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]*$/;
    if (profileData.name === "") {
      toast.error("Name is required");
      valid = false;
    } else if (!regex.test(profileData.name)) {
      toast.error("Name is invalid");
      valid = false;
    } else if (profileData.email === "") {
      // assuming a minimum password length of 6 characters
      toast.error("Email is required");
      valid = false;
    } else if (!emaRegex.test(profileData.email)) {
      toast.error("Email is invalid");
      valid = false;
    } else if (profileData.mobile === "") {
      toast.error("Mobile number is required");
      valid = false;
    } else if (!mobileRegex.test(profileData.mobile)) {
      toast.error("Mobile number is invalid");
      valid = false;
    } else if (profileData.mobile.length !== 10) {
      toast.error("Please enter a valid 10-digit mobile number");
      valid = false;
    }

    return valid;
  };

  // update profile details
  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (!handleValidationProfile()) {
      return;
    }
    const { name, email, mobile } = profileData;
    try {
      const finalData = {
        user_id: sessionData.id,
        name: name,
        email: email,
        mobile: mobile,
      };
      console.log("finalData", finalData);
      const response = await axios.post(
        API_BASE_URL + "api/admin/update_profile_employee",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("change profile response", response.data);
      if (response.data.status === true) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/employee");
          sessionStorage.removeItem("info");
          sessionStorage.setItem("info", JSON.stringify(response.data.data));
          sessionStorage.setItem(
            "info",
            JSON.stringify({ ...response.data.data, user_type: "admin" })
          );
        }, 2000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  return (
    <>
      <Sidebar />
      <div className="body-wrapper">
        <Header />
        {/* Start from here */}
        <div className="container-fluid">
          <div className="card bg-light-info shadow-none position-relative overflow-hidden">
            <div className="card-body px-4 py-3">
              <div className="row align-items-center">
                <div className="col-12 col-md-8 col-lg-9 border-bottom-lg">
                  <h4 className="fw-semibold mb-0">Manage Admin Profile</h4>
                  <p className="mb-0">
                    You can manage your profile from here..
                  </p>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link className="text-muted " to="/admin">
                          Dashboard
                        </Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Manage Profile
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="pills-followers"
              role="tabpanel"
              aria-labelledby="pills-followers-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="card shadow-none">
                    <div
                      className="card-body custom_card_body
                    "
                    >
                      <h4 className="fw-semibold mb-3">
                        Change Profile Details
                      </h4>
                      <hr />
                      <form
                        onSubmit={handleUpdateProfile}
                        className="form-horizontal form-material mt-4"
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                value={profileData.name}
                                onChange={handleInputChange}
                                className="form-control"
                                placeholder="Enter name here"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Email
                              </label>
                              <input
                                type="text"
                                name="email"
                                value={profileData.email}
                                onChange={handleInputChange}
                                className="form-control"
                                placeholder="Enter email here"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Mobile
                              </label>
                              <input
                                type="text"
                                name="mobile"
                                value={profileData.mobile}
                                onChange={handleInputChange}
                                className="form-control"
                                placeholder="Enter mobile here"
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-success waves-effect"
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="pills-followers"
              role="tabpanel"
              aria-labelledby="pills-followers-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="card shadow-none">
                    <div className="card-body custom_card_body_password">
                      <h4 className="fw-semibold mb-3">Change Password</h4>
                      <hr />
                      <form
                        onSubmit={handleUpdatePassword}
                        className="form-horizontal form-material mt-4"
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Current Password
                              </label>
                              <input
                                type="text"
                                name="current_password"
                                value={formData.current_password}
                                onChange={handleInputOnchange}
                                className="form-control"
                                placeholder="Enter current password here"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                New Password
                              </label>
                              <input
                                type="text"
                                name="new_password"
                                value={formData.new_password}
                                onChange={handleInputOnchange}
                                className="form-control"
                                placeholder="Enter new password here"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="form-label" for="tb-pwd">
                                Confirm New Password
                              </label>
                              <input
                                type="text"
                                name="confirm_password"
                                value={formData.confirm_password}
                                onChange={handleInputOnchange}
                                className="form-control"
                                placeholder="Enter confirm password here"
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-success waves-effect"
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </>
  );
};

export default Employee_Profile;
