import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";

const Branch = () => {
  return (
    <>
      <Sidebar />
      <div className="body-wrapper">
        <Header/>
        {/* Start from here */}
        <div className="container-fluid">
          <div className="card bg-light-info shadow-none position-relative overflow-hidden">
            <div className="card-body px-4 py-3">
              <div className="row align-items-center">
                <div className="col-12 col-md-8 col-lg-9 border-bottom-lg">
                  <h4 className="fw-semibold mb-0">Branch Data</h4>
                  <p className="mb-0">You can manage all branches from here..</p>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link className="text-muted " to="/admin">
                          Dashboard
                        </Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Branch
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-info btn-rounded m-t-10 mb-2 me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#add-customer"
                    >
                      <i className="fs-3 ti ti-plus"></i> Add Branch
                    </button>
                    <a className="btn btn-success btn-rounded m-t-10 mb-2" href="#">
                      <i className="fs-3 ti ti-file-spreadsheet"></i> Export
                    </a>
                  </div>
                  <div
                    id="add-customer"
                    className="modal fade in"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-scrollable modal-lg">
                      <div className="modal-content p-3">
                        <div className="modal-header d-flex align-items-center border-bottom">
                          <h4 className="modal-title" id="myModalLabel">
                            Add New Branch
                          </h4>
                          <button
                            type="button"
                            className="btn-close text-white"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body mt-2">
                          <form className="form-horizontal form-material">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label" htmlFor="tb-pwd">
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Name here"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label" htmlFor="tb-pwd">
                                    City
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter city name here"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label" htmlFor="tb-pwd">
                                    Branch Code
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter branch code here"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Other Category
                                  </label>
                                  <select className="form-select">
                                    <option
                                      value=""
                                      selected
                                      disabled
                                      className="text-muted"
                                    >
                                      Select value
                                    </option>
                                    <option value="">Demo 1</option>
                                    <option value="">Demo 2</option>
                                    <option value="">Demo 3</option>
                                  </select>
                                  {/* <small className="form-control-feedback">
                                    Select your gender
                                  </small> */}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label className="form-label" htmlFor="tb-pwd">
                                    Branch Address
                                  </label>
                                  <textarea
                                    className="form-control"
                                    rows="2"
                                    placeholder="Enter address here"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className="btn btn-success waves-effect"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger waves-effect"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="edit-customer"
                    className="modal fade in"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-scrollable modal-lg">
                      <div className="modal-content p-3">
                        <div className="modal-header d-flex align-items-center border-bottom">
                          <h4 className="modal-title" id="myModalLabel">
                            Edit Customer
                          </h4>
                          <button
                            type="button"
                            className="btn-close text-white"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body mt-2">
                          <form className="form-horizontal form-material">
                          <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label" htmlFor="tb-pwd">
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Name here"
                                    value="Gaurav Borse"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label" htmlFor="tb-pwd">
                                    City
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter city name here"
                                    value="Pune"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label" htmlFor="tb-pwd">
                                    Branch Code
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter branch code here"
                                    value="32456"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Other Category
                                  </label>
                                  <select className="form-select">
                                    <option
                                      value=""
                                      disabled
                                      className="text-muted"
                                    >
                                      Select value
                                    </option>
                                    <option value="" selected>Demo 1</option>
                                    <option value="">Demo 2</option>
                                    <option value="">Demo 3</option>
                                  </select>
                                  {/* <small className="form-control-feedback">
                                    Select your gender
                                  </small> */}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label className="form-label" htmlFor="tb-pwd">
                                    Branch Address
                                  </label>
                                  <textarea
                                    className="form-control"
                                    rows="2"
                                    placeholder="Enter address here"
                                  >Narhe, Pune, Maharashtra-422009</textarea>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className="btn btn-success waves-effect"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger waves-effect"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      id="demo-foo-addrow"
                      className="
                        table table-bordered
                        m-t-30
                        table-hover
                        contact-list
                      "
                      data-paging="true"
                      data-paging-size="7"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>City</th>
                          <th>Branch Code</th>
                          <th>Other Category</th>
                          <th>Address</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <a href="javascript:void(0)" className="link">
                              {/* <img
                                src="./assets/images/profile/user-4.jpg"
                                alt="user"
                                width="40"
                                className="rounded-circle"
                              /> */}
                              Narhe
                            </a>
                          </td>
                          <td>rlns.narhe@gmail.com</td>
                          <td>34335</td>
                          
                          <td>Demo 1</td>
                          <td>Narhe</td>
                          <td>
                            <span className="badge bg-success">Active</span>
                          </td>
                          <td>
                            <button
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#edit-customer"
                              className="btn mb-1 btn-warning btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                            >
                              <i className="fs-5 ti ti-edit"></i>
                            </button>

                            <a
                              href="./delete"
                              className="btn mb-1 btn-danger btn-circle btn-sm d-inline-flex align-items-center justify-content-center"
                            >
                              <i className="fs-5 ti ti-trash"></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <a href="javascript:void(0)" className="link">
                              {/* <img
                                src="./assets/images/profile/user-4.jpg"
                                alt="user"
                                width="40"
                                className="rounded-circle"
                              /> */}
                              Kothrud
                            </a>
                          </td>
                          <td>rlns.kothrud@gmail.com</td>
                          <td>45432</td>
                          
                          <td>Demo 2</td>
                          <td>Kothrud</td>
                          <td>
                            <span className="badge bg-danger">Inactive</span>
                          </td>
                          <td>
                            <button
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#edit-customer"
                              className="btn mb-1 btn-warning btn-circle btn-sm d-inline-flex align-items-center justify-content-center me-1"
                            >
                              <i className="fs-5 ti ti-edit"></i>
                            </button>
                            <a
                              href="./delete"
                              className="btn mb-1 btn-danger btn-circle btn-sm d-inline-flex align-items-center justify-content-center"
                            >
                              <i className="fs-5 ti ti-trash"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Branch;