import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const userInfo = JSON.parse(sessionStorage.getItem("info"));
  const userInfoData = JSON.parse(sessionStorage.getItem("EmployeeInfo"));
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      sessionStorage.getItem("info") == null &&
      sessionStorage.getItem("EmployeeInfo") == null
    ) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <aside className="left-sidebar">
        <div>
          <div className="brand-logo d-flex align-items-center justify-content-between">
            <Link
              to={userInfo && userInfo.user_type === "admin" ? "/admin" : "/employee"}
              className="text-nowrap logo-img"
            >
              <img
                src={require("../assets/images/logos/logo.png")}
                className="dark-logo"
                width="130"
                alt=""
              />
              {/* <img src={require('../assets/images/logos/logo.png')} className="light-logo"  width="180" alt="" /> */}
            </Link>
            <div
              className="close-btn d-lg-none d-block sidebartoggler cursor-pointer"
              id="sidebarCollapse"
            >
              <i className="ti ti-x fs-8 text-muted"></i>
            </div>
          </div>
          {userInfo && userInfo.user_type === "admin" ? (
            <nav className="sidebar-nav scroll-sidebar" data-simplebar>
              <ul id="sidebarnav">
                <li className="nav-small-cap">
                  <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                  {/* <span className="hide-menu">Dashboard</span> */}
                </li>
                <li
                  className={
                    location.pathname === "/admin"
                      ? `sidebar-item selected`
                      : `sidebar-item`
                  }
                >
                  <Link
                    className={
                      location.pathname === "/admin"
                        ? `sidebar-link justify-content-between active`
                        : `sidebar-link justify-content-between`
                    }
                    to="/admin"
                    aria-expanded="false"
                    // onClick={() => (window.location.href = window.location.href("/admin"))}
                  >
                    <div className="d-flex align-items-center gap-3">
                      <span className="d-flex">
                        <i className="ti ti-layout-grid"></i>
                      </span>
                      <span className="hide-menu">Dashboard</span>
                    </div>
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === "/admin/customers"
                      ? `sidebar-item selected`
                      : `sidebar-item`
                  }
                >
                  <Link
                    className={
                      location.pathname === "/admin/customers" ||
                      location.pathname.includes("/admin/customer-profile")
                        ? `sidebar-link active`
                        : `sidebar-link`
                    }
                    to="/admin/customers"
                    aria-expanded="false"
                  >
                    <span className="d-flex">
                      <i className="ti ti-users"></i>
                    </span>
                    <span className="hide-menu">Customers</span>
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === "/admin/employees"
                      ? `sidebar-item selected`
                      : `sidebar-item`
                  }
                >
                  <Link
                    className={
                      location.pathname === "/admin/employees"
                        ? `sidebar-link justify-content-between active`
                        : `sidebar-link justify-content-between`
                    }
                    to="/admin/employees"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center gap-3">
                      <span className="d-flex">
                        <i className="ti ti-user-star"></i>
                      </span>
                      <span className="hide-menu">Employees</span>
                    </div>
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === "/admin/enquiry" ||
                    location.pathname.includes("/admin/job-detail/")
                      ? `sidebar-item selected`
                      : `sidebar-item`
                  }
                >
                  <Link
                    className={
                      location.pathname === "/admin/enquiry" ||
                      location.pathname.includes("/admin/job-detail/")
                        ? `sidebar-link justify-content-between active`
                        : `sidebar-link justify-content-between`
                    }
                    to="/admin/enquiry"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center gap-3">
                      <span className="d-flex">
                        <i className="ti ti-file-description"></i>
                      </span>
                      <span className="hide-menu">Enquiries</span>
                    </div>
                  </Link>
                </li>
                {/* <li
                  className={
                    location.pathname === "/admin/report"
                      ? `sidebar-item selected`
                      : `sidebar-item`
                  }
                >
                  <Link
                    className={
                      location.pathname === "/admin/report"
                        ? `sidebar-link justify-content-between active`
                        : `sidebar-link justify-content-between`
                    }
                    to="/admin/report"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center gap-3">
                      <span className="d-flex">
                        <i className="ti ti-report"></i>
                      </span>
                      <span className="hide-menu">Report</span>
                    </div>
                  </Link>
                </li> */}
                <li
                  className={
                    location.pathname === "/admin/enquiry-charges"
                      ? `sidebar-item selected`
                      : `sidebar-item`
                  }
                >
                  <Link
                    className={
                      location.pathname === "/admin/enquiry-charges"
                        ? `sidebar-link justify-content-between active`
                        : `sidebar-link justify-content-between`
                    }
                    to="/admin/enquiry-charges"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center gap-3">
                      <span className="d-flex">
                        <i className="ti ti-coin-rupee"></i>
                      </span>
                      <span className="hide-menu">Enquiry Charges</span>
                    </div>
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === "/admin/profile"
                      ? `sidebar-item selected`
                      : `sidebar-item`
                  }
                >
                  <Link
                    className={
                      location.pathname === "/admin/profile"
                        ? `sidebar-link justify-content-between active`
                        : `sidebar-link justify-content-between`
                    }
                    to="/admin/profile"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center gap-3">
                      <span className="d-flex">
                        <i className="ti ti-settings"></i>
                      </span>
                      <span className="hide-menu">Manage Profile</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </nav>
          ) : userInfoData && userInfoData.user_type === "employee" ? (
            <nav className="sidebar-nav scroll-sidebar" data-simplebar>
              <ul id="sidebarnav">
                <li className="nav-small-cap">
                  <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                  {/* <span className="hide-menu">Dashboard</span> */}
                </li>
                <li
                  className={
                    location.pathname === "/employee"
                      ? `sidebar-item selected`
                      : `sidebar-item`
                  }
                >
                  <Link
                    className={
                      location.pathname === "/employee"
                        ? `sidebar-link justify-content-between active`
                        : `sidebar-link justify-content-between`
                    }
                    to="/employee"
                    aria-expanded="false"
                    // onClick={() => (window.location.href = window.location.href("/admin"))}
                  >
                    <div className="d-flex align-items-center gap-3">
                      <span className="d-flex">
                        <i className="ti ti-layout-grid"></i>
                      </span>
                      <span className="hide-menu">Dashboard</span>
                    </div>
                  </Link>
                </li>

                <li
                  className={
                    location.pathname === "/employee/enquiry" ||
                    location.pathname.includes("/employee/job-detail/")
                      ? `sidebar-item selected`
                      : `sidebar-item`
                  }
                >
                  <Link
                    className={
                      location.pathname === "/employee/enquiry"
                        ? `sidebar-link justify-content-between active`
                        : `sidebar-link justify-content-between`
                    }
                    to="/employee/enquiry"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center gap-3">
                      <span className="d-flex">
                        <i className="ti ti-file-description"></i>
                      </span>
                      <span className="hide-menu">Assigned Enquiries</span>
                    </div>
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === "/employee/profile"
                      ? `sidebar-item selected`
                      : `sidebar-item`
                  }
                >
                  <Link
                    className={
                      location.pathname === "/employee/profile"
                        ? `sidebar-link justify-content-between active`
                        : `sidebar-link justify-content-between`
                    }
                    to="/employee/profile"
                    aria-expanded="false"
                  >
                    <div className="d-flex align-items-center gap-3">
                      <span className="d-flex">
                        <i className="ti ti-settings"></i>
                      </span>
                      <span className="hide-menu">Manage Profile</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </nav>
          ) : (
            ""
          )}
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
